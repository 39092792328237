import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { findLast } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";
import type {
  timesheetValue_timesheet$data,
  timesheetValue_timesheet$key
} from "./__generated__/timesheetValue_timesheet.graphql";

export type EngagementTimesheet = timesheetValue_timesheet$data;
export type EngagementTimesheetState = NonNullable<
  ObjectPath<EngagementTimesheet, ["states", "edges", 0, "node"]>
>;

export function useTimesheetData(
  timesheetRef?: timesheetValue_timesheet$key | null,
  startsAt?: string | null,
  endsAt?: string | null,
  timezone?: string | null,
  create = false
) {
  const timesheet = useRelayFragment(
    graphql`
      fragment timesheetValue_timesheet on EngagementTimesheet
      @argumentDefinitions(
        overridden: { type: "Boolean", defaultValue: false }
      ) {
        id
        totalDurationWorked
        estimatedMileage
        states(
          first: 50
          overridden: $overridden
          orderBy: [{transitionedAt: {direction: ASC}}]
        ) {
          edges {
            node {
              __typename
              id
              name
              transitionedAt
              action
              ... on EngagementStateOverride {
                overridesState {
                  id
                }
              }
            }
          }
        }
      }
    `,
    timesheetRef ?? null
  );

  return useMemo(() => {
    timezone ||= undefined;
    const startTime =
      findStartTime(create, timesheet, startsAt, timezone) ??
      DateTime.local().setZone(timezone);
    const endTime =
      findEndTime(create, timesheet, endsAt, timezone) ??
      startTime.plus({ minutes: 1 });

    const { breaks, breakValues } = create
      ? { breaks: [], breakValues: {} }
      : findInitialBreaks(timesheet, timezone);

    const originalStartsAt = findStartTime(
      false,
      timesheet,
      undefined,
      timezone
    )
      ?.plus({ milliseconds: 5 })
      .toISO();

    return {
      timesheetId: timesheet?.id,
      timesheetStates: getConnectionNodes(timesheet?.states),
      mileage: `${Number(timesheet?.estimatedMileage ?? 0)}`,
      breakValues,
      originalStartsAt,
      engagementStartTime: startsAt
        ? DateTime.fromISO(startsAt, { zone: timezone })
        : null,
      initialStartTime: startTime,
      initialEndTime: endTime,
      initialBreaks: breaks
    };
  }, [timesheet, startsAt, endsAt, timezone, create]);
}

export function useTimesheetInitialValues(
  timesheetRef?: timesheetValue_timesheet$key | null,
  startsAt?: string | null,
  endsAt?: string | null,
  timezone?: string | null,
  create?: boolean
) {
  const data = useTimesheetData(
    timesheetRef,
    startsAt,
    endsAt,
    timezone,
    create
  );
  return useMemo(
    () => ({
      ...data,
      initialValues: {
        ...data.breakValues,
        startTimeDate: data.initialStartTime.startOf("day"),
        startTimeTime: create ? "" : data.initialStartTime.toFormat("hh:mm"),
        startTimeAmpm: create ? "AM" : data.initialStartTime.toFormat("a"),
        endTimeDate: data.initialEndTime.startOf("day"),
        endTimeTime: create ? "" : data.initialEndTime.toFormat("hh:mm"),
        endTimeAmpm: create ? "PM" : data.initialEndTime.toFormat("a"),
        includeBreaks: data.initialBreaks.length > 0,
        mileage: data.mileage || undefined
      }
    }),
    [data]
  );
}

function findStartTime(
  isCreate?: boolean,
  timesheet?: timesheetValue_timesheet$data | null,
  engagementStartsAt?: string | null,
  timezone?: string
) {
  let startsAt = engagementStartsAt;
  if (!isCreate) {
    startsAt =
      timesheet?.states?.edges?.find((d) => d?.node?.action === "START")?.node
        ?.transitionedAt ?? startsAt;
  }

  // starts at not found. try grabbing from SCHEDULED state
  if (!startsAt) {
    startsAt = findLast(
      timesheet?.states?.edges,
      (it) => it?.node?.name === "SCHEDULED"
    )?.node?.transitionedAt;
  }

  return startsAt ? DateTime.fromISO(startsAt, { zone: timezone }) : null;
}

function findEndTime(
  isCreate?: boolean,
  timesheet?: timesheetValue_timesheet$data | null,
  engagementEndsAt?: string | null,
  timezone?: string
) {
  let endsAt = engagementEndsAt;
  if (!isCreate) {
    endsAt =
      timesheet?.states?.edges?.find(
        (d) =>
          d?.node?.action === "END" || d?.node?.action === "EXCEED_DURATION"
      )?.node?.transitionedAt ?? endsAt;
  }
  return endsAt ? DateTime.fromISO(endsAt, { zone: timezone }) : null;
}

const findInitialBreaks = (
  timesheet?: timesheetValue_timesheet$data | null,
  timezone?: string
) => {
  let statesArray = timesheet?.states?.edges ?? [];

  let breaksFound = true;
  const breaks: Record<string, DateTime | string> = {};
  let breakCount = 0;
  while (breaksFound) {
    const pause = statesArray.find(
      (stateEdge) => stateEdge?.node?.action === "PAUSE"
    );
    statesArray = statesArray.filter((state) => state !== pause);
    if (pause) {
      const resume = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "RESUME"
      );
      const end = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "END"
      );
      const exceedDuration = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "EXCEED_DURATION"
      );

      const endOfBreak = resume ?? end ?? exceedDuration;

      if (endOfBreak) {
        ++breakCount;
        breaks[`break${breakCount}StartDate`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).startOf("day");
        breaks[`break${breakCount}StartTime`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).toFormat("hh:mm");
        breaks[`break${breakCount}StartAmpm`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).toFormat("a");
        breaks[`break${breakCount}EndDate`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).startOf("day");
        breaks[`break${breakCount}EndTime`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).toFormat("hh:mm");
        breaks[`break${breakCount}EndAmpm`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).toFormat("a");
        statesArray = statesArray.filter((state) => state !== endOfBreak);
      }
    } else {
      breaksFound = false;
    }
  }
  return {
    breaks: [...Array(breakCount).keys()].map((key) => key + 1),
    breakValues: breaks
  };
};
