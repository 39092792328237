/**
 * @generated SignedSource<<bbe71ba7c4b980933458c39a33244d2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementDisputeSummaryTabs_EngagementTimesheet$data = {
  readonly disputedFinalTimesheet: {
    readonly diff: {
      readonly additionalPayment: string;
      readonly basePayment: string;
      readonly billableDuration: string;
      readonly totalPayment: string;
      readonly totalWorkerServiceFeeAmount: string;
    } | null | undefined;
    readonly engagementTimesheet: {
      readonly paymentInfo: {
        readonly billableDuration: string;
        readonly netPay: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly paymentInfo: {
    readonly billableDuration: string;
    readonly netPay: string;
    readonly payRate: string;
  } | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  readonly " $fragmentType": "EngagementDisputeSummaryTabs_EngagementTimesheet";
};
export type EngagementDisputeSummaryTabs_EngagementTimesheet$key = {
  readonly " $data"?: EngagementDisputeSummaryTabs_EngagementTimesheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummaryTabs_EngagementTimesheet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EngagementDisputeSummaryTabs_EngagementTimesheet",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "overridden",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "TimesheetList_timesheet"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "basePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWorkerServiceFeeAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additionalPayment",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheet",
          "kind": "LinkedField",
          "name": "engagementTimesheet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPaymentInfo",
              "kind": "LinkedField",
              "name": "paymentInfo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
};
})();

(node as any).hash = "b9a44cbed41954d3ba67a06081ebd0f0";

export default node;
