import { Timesheet, type TimesheetEntry } from "@gigsmart/atorasu";
import { WorkerLocationTracking } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import showWorkerLocationMapModal from "../location-tracking/WorkerLocationMapModal";
import type {
  EngagementStateName,
  TimesheetList_timesheet$key
} from "./__generated__/TimesheetList_timesheet.graphql";
import { generateEntriesFromStates } from "./bids/helpers";

const LabelDictionary: Record<
  Props["variant"],
  { [key in EngagementStateName]?: string }
> = {
  worker: {
    BID_REQUESTED: "Offered - Request to Bid",
    APPLIED: "Applied",
    BID_REVIEW: "Bid Submitted",
    AWAITING_START: "Actual Arrival",
    CANCELED: "Shift Canceled",
    EN_ROUTE: "En Route",
    PAYMENT_FAILED: "Shift Ended",
    INITIAL: "Shift Created",
    MISSED: "Shift Missed",
    OFFERED: "Shift Offered",
    PAUSED: "Shift Paused",
    SCHEDULED: "Shift Scheduled",
    UNAVAILABLE: "Worker Became Unavailable",
    WORKING: "Shift Resumed",
    PENDING_TIMESHEET_APPROVAL: "Shift Clock Ended",
    ENDED: "Timesheet Approved",
    RUNNING_LATE: "Marked as Late"
  },
  requester: {
    BID_REQUESTED: "Offered - Request to Bid",
    APPLIED: "Applied",
    BID_REVIEW: "Worker Bid Submitted",
    OFFERED: "Offered",
    SCHEDULED: "Scheduled",
    EN_ROUTE: "En Route",
    AWAITING_START: "Awaiting Start",
    CANCELED: "Shift Canceled",
    ENDED: "Shift Ended",
    PAYMENT_FAILED: "Shift Ended",
    PENDING_TIMESHEET_APPROVAL: "Shift Ended",
    PAUSED: "Shift Paused",
    WORKING: "Shift Resumed",
    RUNNING_LATE: "Marked as Late"
  }
};

const ValidEndStates: Record<Props["variant"], EngagementStateName[] | null> = {
  requester: ["ENDED", "PAYMENT_FAILED", "PENDING_TIMESHEET_APPROVAL"],
  worker: null
};

interface Props {
  variant: "requester" | "worker";
  workerEta?: string | null;
  endsAt?: string | null;
  showOverrideTagsIfApproved?: boolean;
}

const bidHistoryStates = ["BID_REQUESTED", "BID_REVIEW", "OFFERED"];

export const TimesheetList = ({
  isApproved,
  id,
  states: timesheetListStates,
  variant,
  workerEta,
  endsAt,
  showOverrideTagsIfApproved,
  timezone
}: FragmentContainerInnerComponentProps<
  TimesheetList_timesheet$key,
  Props
>) => {
  // map timesheet entries
  const entries = useMemo(() => {
    const { bidHistoryEntries = [], nonBidHistoryEntries = [] } = groupBy(
      timesheetListStates?.edges ?? [],
      (edge) =>
        edge?.node?.name && bidHistoryStates.includes(edge?.node?.name)
          ? "bidHistoryEntries"
          : "nonBidHistoryEntries"
    );

    const endStates = ValidEndStates[variant];
    const total = bidHistoryEntries.length + nonBidHistoryEntries.length;

    let isWorking = false;
    let isActive = false;
    let isComplete = false;

    const result: TimesheetEntry[] =
      generateEntriesFromStates(bidHistoryEntries);

    nonBidHistoryEntries.forEach((edge, idx) => {
      const __node = edge?.node;
      if (!__node) return;

      const {
        __typename,
        id: engagementStateId,
        name: stateName,
        action,
        transitionedAt,
        overridesState
      } = __node;
      let event = stateName && LabelDictionary[variant][stateName];
      if (isComplete || !event) return;

      if (stateName === "WORKING" && !isWorking) {
        isWorking = true;
        event = variant === "requester" ? "Worker Started" : "Shift Started";
      }

      let tag: string | undefined;
      if (
        (!isApproved || showOverrideTagsIfApproved) &&
        __typename === "EngagementStateOverride"
      ) {
        tag = overridesState?.id ? "Adjusted" : "Added";
      }

      const isLast = idx === total - 1;
      if (isActive) {
        if (stateName === "PAUSED" || stateName === "ENDED") isActive = false;
      } else {
        if (stateName === "WORKING") isActive = true;
      }

      if (stateName === "AWAITING_START" && workerEta) {
        result.push({
          ...createEntry("Expected Arrival", workerEta, timezone ?? ""),
          active: isActive
        });
      }

      if (stateName === "AWAITING_START") {
        event =
          action === "REQUEST_START" ? "Requested Start" : "Actual Arrival";
      }
      const laterEndNode = timesheetListStates?.edges?.find(
        (d) =>
          d?.node?.name === "PENDING_TIMESHEET_APPROVAL" &&
          DateTime.fromISO(d.node.transitionedAt) >
            DateTime.fromISO(transitionedAt)
      );

      const showLocationIcon =
        (WorkerLocationTracking.isEnabled() &&
          variant === "requester" &&
          [
            "AWAITING_START",
            "WORKING",
            "PAUSED",
            "ENDED",
            "PENDING_TIMESHEET_APPROVAL"
          ].includes(stateName) &&
          __typename !== "EngagementStateOverride") ||
        (stateName === "RUNNING_LATE" && __node.workerTransitionLocation);

      if (!laterEndNode || !endStates?.includes(stateName)) {
        result.push({
          ...createEntry(event, transitionedAt, timezone ?? ""),
          active: isLast || isActive,
          disabled: false,
          tag,
          icon: showLocationIcon ? "location-dot" : undefined,
          iconPosition: "right",
          onIconPress: () =>
            showWorkerLocationMapModal({
              engagementStateId,
              engagementTimesheetId: id
            })
        });
      }

      isComplete =
        isComplete || (!!endStates?.includes(stateName) && !laterEndNode);
    });

    if (endsAt) {
      const skipEndHint =
        variant === "requester"
          ? isComplete
          : !!timesheetListStates?.edges?.find(
              (it) =>
                it?.node?.name !== "PENDING_TIMESHEET_APPROVAL" &&
                it?.node?.name !== "ENDED"
            );

      if (!skipEndHint) {
        result.push(createEntry("Scheduled Shift End", endsAt, timezone ?? ""));
      }
    }

    return result;
  }, [
    timesheetListStates,
    variant,
    endsAt,
    isApproved,
    showOverrideTagsIfApproved,
    workerEta,
    timezone
  ]);
  //
  // finish mapping states into entries

  return <Timesheet entries={entries} />;
};

export default createRelayFragmentContainer<TimesheetList_timesheet$key, Props>(
  graphql`
    fragment TimesheetList_timesheet on EngagementTimesheet
    @argumentDefinitions(overridden: { type: "Boolean!" }) {
      id
      timezone
      isApproved
      states(
        first: 50
        overridden: $overridden
        orderBy: [{transitionedAt: {direction: ASC}}]
      ) {
        edges {
          node {
            __typename
            id
            name
            action
            transitionedAt
            transitionedBy {
              __typename
              displayName
            }
            negotiation {
              payRate
              submittedBy {
                __typename
                displayName
              }
            }
            ... on EngagementStateOverride {
              overridesState {
                id
              }
            }
            ... on EngagementState {
              workerTransitionLocation {
                latitude
              }
            }
          }
        }
      }
    }
  `,
  TimesheetList
);

//
// private

const createEntry = (
  event: string,
  timestamp: string | null | undefined,
  timezone: string
): TimesheetEntry => ({
  label: event,
  testID: event,
  timestamp: timezone
    ? DateTime.fromISO(timestamp ?? "", { zone: timezone }).toISO()
    : (timestamp as string),
  disabled: true,
  active: true
});
