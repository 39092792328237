import { Validator, type ValueMap, useFormFields } from "@gigsmart/fomu";
import type { Validator as ValidatorType } from "@gigsmart/fomu/types";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import { DateTime, Duration } from "luxon";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useMemo } from "react";

const A_MINUTE = 1;
const A_HOUR = 60;
const A_DAY = 24 * A_HOUR;

function getHoursAndMinutes(time: string): number[] {
  const [hours = 0, minutes = 0] = time
    ? time.split(":").map((num) => (num ? Number.parseInt(num, 10) : 0))
    : [];
  return [hours, minutes];
}

const timeValidator = Validator.time();

export function useTimesheetInputFields(
  name: string,
  validator: ValidatorType<any> | Array<ValidatorType<any>> | null
) {
  const fieldValidator = useMemo(() => {
    if (!validator) return timeValidator;
    return Array.isArray(validator)
      ? Validator.composeValidations(timeValidator, ...validator)
      : Validator.composeValidations(timeValidator, validator);
  }, [validator]);

  const fields = useFormFields({
    [`${name}Date`]: null,
    [`${name}Ampm`]: null,
    [`${name}Time`]: fieldValidator
  });
  const handleInputDestroy = useCallback(() => {
    fields[`${name}Ampm`]?.removeField();
    fields[`${name}Date`]?.removeField();
    fields[`${name}Time`]?.removeField();
  }, [fields]);

  useEffect(() => {
    fields[`${name}Time`]?.showErrors();
  }, []);

  const timeValue: string = fields[`${name}Time`]?.value ?? "";
  const isIncomplete = timeValue.length < 4;
  return {
    ampm: fields[`${name}Ampm`]?.value,
    date: fields[`${name}Date`]?.value,
    time: fields[`${name}Time`]?.value,
    onChangeAmpm: fields[`${name}Ampm`]?.setValue,
    onChangeDate: fields[`${name}Date`]?.setValue,
    onChangeTime: fields[`${name}Time`]?.setValue,
    error: isIncomplete ? null : fields[`${name}Time`]?.errorMessage,
    handleInputDestroy
  };
}

export function getDateTimeFromInputValues(
  time: string,
  ampm: "AM" | "PM" | null,
  date?: DateTime | null,
  timezone?: string | null
): DateTime {
  const [hours, minutes] = getHoursAndMinutes(time);
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  let computedHours = hours! + (ampm === "PM" ? 12 : 0);
  if (hours === 12) computedHours = ampm === "AM" ? 0 : 12;
  return (
    (
      date?.setZone(timezone ?? undefined) ??
      DateTime.local().setZone(timezone ?? undefined)
    )
      .startOf("day")
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      .plus({ minutes: minutes! + computedHours * 60 })
  );
}

export const createStartTimeValidator = Validator.create<
  {
    earliestStartTime: DateTime;
    dateTimeFieldName: string;
    showScheduledError: boolean;
    breaks?: number[];
    isRequester?: boolean;
    timezone?: string | null;
  },
  string
>(
  ({
    earliestStartTime,
    dateTimeFieldName,
    showScheduledError,
    breaks = [],
    isRequester,
    timezone
  }) =>
    (fieldName, value, otherFields) => {
      const date: DateTime | null = otherFields
        ?.get(`${dateTimeFieldName}Date`)
        ?.setZone(timezone);
      const ampm = otherFields?.get(`${dateTimeFieldName}Ampm`);
      const breakDates = breaks.map((i) =>
        constructDateTime(`break${i}Start`, otherFields)
      );

      if (!value || !date || !ampm) {
        return ampm ? [new Error("Invalid Time")] : [new Error("")];
      }
      const dateTimeValue = getDateTimeFromInputValues(
        value,
        ampm,
        date,
        timezone
      );
      if (!dateTimeValue) return null;
      if (
        breakDates.some((d) => (d ?? DateTime.local()).equals(dateTimeValue))
      ) {
        return [
          new Error("Start Time and Break Start Time cannot be the same")
        ];
      }
      if (
        dateTimeValue.setZone(timezone ?? undefined).toMillis() <
        earliestStartTime.setZone(timezone ?? undefined).toMillis()
      ) {
        const errorMessage = showScheduledError
          ? `Cannot be earlier than when ${
              isRequester ? "the worker was hired" : "you accepted the Gig"
            } at ${earliestStartTime
              .setZone(timezone ?? undefined)
              .toFormat("h:mm a ZZZZ")}`
          : "Cannot be earlier than 3 hours before scheduled Gig start time";
        return [new Error(errorMessage)];
      }
      if (dateTimeValue > DateTime.local().setZone(timezone ?? undefined)) {
        return [new Error("Start Time cannot be in the future")];
      }
      return null;
    }
);

export const createEndTimeValidator = Validator.create<
  {
    dateTimeFieldName: string;
    breaks?: number[];
    timezone?: string | null;
  },
  string
>(({ dateTimeFieldName, breaks = [], timezone }) => (_, value, otherFields) => {
  const date = otherFields?.get(`${dateTimeFieldName}Date`);
  const ampm = otherFields?.get(`${dateTimeFieldName}Ampm`);
  const breakDates = breaks.map((i) =>
    constructDateTime(`break${i}End`, otherFields, undefined)
  );

  if (!value || !date || !ampm) {
    return ampm ? [new Error("Invalid Time")] : [new Error("")];
  }

  const dateTimeValue = getDateTimeFromInputValues(value, ampm, date, timezone);
  if (!dateTimeValue) return null;
  if (breakDates.some((d) => d?.equals(dateTimeValue))) {
    return [new Error("End Time and Break End Time cannot be the same")];
  }

  const currentTime = DateTime.local();
  if (
    dateTimeValue.hasSame(currentTime, "minute") &&
    dateTimeValue.hasSame(currentTime, "hour")
  ) {
    return [new Error("Cannot equal current time")];
  }
  return null;
});

export const createEndTimeGigDurationValidator = Validator.create<
  {
    dateTimeFieldName: string;
    minimumPaidGigDuration?: string | null;
  },
  string
>(
  ({ dateTimeFieldName, minimumPaidGigDuration }) =>
    (_, value, otherFields) => {
      const dateTimeValue = constructDateTime(
        dateTimeFieldName,
        otherFields,
        value
      );
      if (!dateTimeValue) return null;
      if (dateTimeValue > DateTime.local()) {
        return [new Error("End Time cannot be in the future")];
      }

      const startTime = constructDateTime("startTime", otherFields);
      if (!startTime) return null;

      const gigDuration = dateTimeValue.diff(startTime, "minutes").minutes;

      if (gigDuration < 0) {
        return [new Error("End Time cannot be before Start Time")];
      }
      if (gigDuration === 0 || gigDuration < A_MINUTE) {
        return [new Error("Start Time and End Time cannot be the same")];
      }
      if (gigDuration > A_DAY) {
        return [
          new Error(
            "End Time cannot be more than 23 hours and 59 minutes after Start Time"
          )
        ];
      }
      if (minimumPaidGigDuration) {
        const minimumPaidGigDurationMinutes = Duration.fromISO(
          minimumPaidGigDuration
        ).as("minutes");
        if (minimumPaidGigDurationMinutes >= gigDuration) {
          return [
            new Error(
              `Duration must exceed paid duration of ${duration.humanize(
                minimumPaidGigDuration,
                "semi-compact-no-spaces"
              )}`
            )
          ];
        }
      }
      return null;
    }
);

export const createBreakStartValidator = Validator.create<
  {
    dateTimeFieldName: string;
    initialValues: Record<string, any>;
    breaks?: number[];
    gigStartOverride?: DateTime | null;
    gigEndOverride?: DateTime | null;
  },
  string
>(
  ({
    dateTimeFieldName,
    initialValues,
    breaks = [],
    gigStartOverride,
    gigEndOverride
  }) =>
    (fieldName, value, otherValues) => {
      const breakStart = constructDateTime(
        `${dateTimeFieldName}Start`,
        otherValues,
        value
      );
      if (!breakStart) {
        const ampm = otherValues?.get(`${dateTimeFieldName}StartAmpm`);
        return ampm ? [new Error("Invalid Time")] : [new Error("")];
      }

      const gigStart =
        gigStartOverride ?? constructDateTime("startTime", otherValues);
      if (gigStart && breakStart < gigStart) {
        return [new Error("Break Start Time cannot occur before Start Time")];
      }
      if (gigStart && breakStart.hasSame(gigStart, "minute")) {
        return [
          new Error("Break Start Time and Start Time cannot be the same")
        ];
      }
      const gigEnd =
        gigEndOverride ?? constructDateTime("endTime", otherValues);
      if (gigEnd && breakStart > gigEnd) {
        return [new Error("Break Start Time cannot occur after End Time")];
      }
      if (gigEnd && breakStart.hasSame(gigEnd, "minute")) {
        return [new Error("Break Start Time and End Time cannot be the same")];
      }
      const breakEnd = constructDateTime(
        `${dateTimeFieldName}End`,
        otherValues
      );
      if (breakEnd && breakStart > breakEnd) {
        return [
          new Error("Break Start Time cannot occur after Break End Time")
        ];
      }
      if (breakEnd && breakStart.hasSame(breakEnd, "minute")) {
        let isInitialBreak = false;
        breaks.forEach((index) => {
          const startTime = initialValues[`break${index}StartTime`];
          const startAmpm = initialValues[`break${index}StartAmpm`];
          const endTime = initialValues[`break${index}EndTime`];
          const endAmpm = initialValues[`break${index}EndAmpm`];
          if (
            `${startTime}${startAmpm}` === breakStart.toFormat("hh:mmA") &&
            `${endTime}${endAmpm}` === breakEnd.toFormat("hh:mmA")
          )
            isInitialBreak = true;
        });
        return isInitialBreak
          ? null
          : [
              new Error("Break Start Time cannot be the same as Break End Time")
            ];
      }
      return null;
    }
);

export const createBreakOverlapValidator = Validator.create<
  {
    dateTimeFieldName: string;
    breaks: number[];
  },
  string
>(({ dateTimeFieldName, breaks }) => (fieldName, value, otherValues) => {
  const breakTime = constructDateTime(dateTimeFieldName, otherValues, value);
  if (!breakTime) return null;
  let overlapFound = false;
  breaks.forEach((otherBreak) => {
    if (!dateTimeFieldName.startsWith(`break${otherBreak}`)) {
      const breakStart = constructDateTime(
        `break${otherBreak}Start`,
        otherValues
      );
      const breakEnd = constructDateTime(`break${otherBreak}End`, otherValues);
      if (breakStart && breakEnd) {
        if (
          (breakTime > breakStart && breakTime < breakEnd) ||
          breakTime.hasSame(breakStart, "minute") ||
          breakTime.hasSame(breakEnd, "minute")
        ) {
          overlapFound = true;
        }
      }
    }
  });

  return overlapFound ? [new Error("Breaks cannot overlap each other")] : null;
});

export const createBreakEndValidator = Validator.create<
  {
    dateTimeFieldName: string;
    initialValues: Record<string, any>;
    breaks: number[];
    gigStartOverride?: DateTime | null;
    gigEndOverride?: DateTime | null;
  },
  string
>(
  ({
    dateTimeFieldName,
    initialValues,
    breaks,
    gigStartOverride,
    gigEndOverride
  }) =>
    (fieldName, value, otherValues) => {
      const breakEnd = constructDateTime(
        `${dateTimeFieldName}End`,
        otherValues,
        value
      );
      if (!breakEnd) {
        const ampm = otherValues?.get(`${dateTimeFieldName}EndAmpm`);
        return ampm ? [new Error("Invalid Time")] : [new Error("")];
      }
      const breakStart = constructDateTime(
        `${dateTimeFieldName}Start`,
        otherValues
      );
      if (breakStart && breakEnd < breakStart) {
        return [
          new Error("Break End Time cannot occur before Break Start Time")
        ];
      }
      if (breakStart && breakEnd.hasSame(breakStart, "minute")) {
        let isInitialBreak = false;
        breaks.forEach((index) => {
          const startTime = initialValues[`break${index}StartTime`];
          const startAmpm = initialValues[`break${index}StartAmpm`];
          const endTime = initialValues[`break${index}EndTime`];
          const endAmpm = initialValues[`break${index}EndAmpm`];
          if (
            `${startTime}${startAmpm}` === breakStart.toFormat("hh:mmA") &&
            `${endTime}${endAmpm}` === breakEnd.toFormat("hh:mmA")
          )
            isInitialBreak = true;
        });
        return isInitialBreak
          ? null
          : [
              new Error("Break End Time cannot be the same as Break Start Time")
            ];
      }
      const gigStart =
        gigStartOverride ?? constructDateTime("startTime", otherValues);
      if (gigStart && breakEnd < gigStart) {
        return [new Error("Break End Time cannot occur before Start Time")];
      }
      if (gigStart && breakEnd.hasSame(gigStart, "minute")) {
        return [
          new Error("Break End Time cannot be the same as the Start Time")
        ];
      }
      const gigEnd =
        gigEndOverride ?? constructDateTime("endTime", otherValues);
      if (gigEnd && breakEnd > gigEnd) {
        return [new Error("Break End Time cannot occur after End Time")];
      }
      if (gigEnd && breakEnd.hasSame(gigEnd, "minute")) {
        return [new Error("Break End Time and End Time cannot be the same")];
      }
      return null;
    }
);

export const createBreakMinimumDurationValidator = Validator.create<
  {
    dateTimeFieldName: string;
    breaks: number[];
    minimumPaidGigDuration?: string | null;
    gigStartOverride?: DateTime | null;
    gigEndOverride?: DateTime | null;
  },
  string
>(
  ({ breaks, minimumPaidGigDuration, gigStartOverride, gigEndOverride }) =>
    (fieldName, value, otherValues) => {
      const breakDurations: Duration[] = breaks
        ?.map((i) => {
          const start = constructDateTime(
            `break${i}Start`,
            otherValues,
            undefined
          );
          const end = constructDateTime(`break${i}End`, otherValues, undefined);
          if (start && end) {
            return end?.diff(start, "minutes");
          }
          return null;
        })
        .filter((dur): dur is Duration => dur !== null);
      const startTime =
        gigStartOverride ?? constructDateTime("startTime", otherValues);
      const endTime =
        gigEndOverride ?? constructDateTime("endTime", otherValues);
      if (startTime && endTime) {
        const gigDuration = endTime?.diff(startTime, "minutes");
        const gigDurationMinusBreaks = breakDurations.reduce(
          (prev, current) => prev?.minus(current),
          gigDuration
        );
        if (minimumPaidGigDuration) {
          const minimumPaidGigDurationMinutes = Duration.fromISO(
            minimumPaidGigDuration
          ).as("minutes");
          if (
            minimumPaidGigDurationMinutes >=
            gigDurationMinusBreaks.as("minutes")
          ) {
            return [
              new Error(
                `Total duration must exceed paid duration of ${duration.humanize(
                  minimumPaidGigDuration,
                  "semi-compact-no-spaces"
                )}`
              )
            ];
          }
        }
      }
      return null;
    }
);

function constructDateTime(
  fieldNamePrefix: string,
  otherFields: ValueMap | null | undefined,
  fieldValue?: string
) {
  const time = fieldValue ?? otherFields?.get(`${fieldNamePrefix}Time`);
  const ampm = otherFields?.get(`${fieldNamePrefix}Ampm`);
  const date = otherFields?.get(`${fieldNamePrefix}Date`);
  if (!time || !ampm || !date) return null;
  return getDateTimeFromInputValues(time, ampm, date);
}
