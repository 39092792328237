import {
  CollapsibleRow,
  ContentArea,
  Divider,
  HighlightedReminder,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency, duration, time } from "@gigsmart/isomorphic-shared/iso";
import { TabLayout } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useState } from "react";
import TimesheetList from "./TimesheetList";
import { WorkerGigReceipt } from "./WorkerGigReceipt";
import type { WorkerShiftInfoCollapsible_engagement$key } from "./__generated__/WorkerShiftInfoCollapsible_engagement.graphql";

export function WorkerShiftInfoCollapsible({
  paymentInfo,
  gig,
  currentState,
  timesheet
}: FragmentContainerInnerComponentProps<WorkerShiftInfoCollapsible_engagement$key>) {
  const [activeTab, setActiveTab] = useState<"payment" | "timesheet">(
    "payment"
  );
  const startsAt = timesheet?.states?.edges?.find(
    (state) => state?.node?.action === "START"
  )?.node?.transitionedAt;
  const endsAt = timesheet?.states?.edges?.find(
    (state) => state?.node?.action === "END"
  )?.node?.transitionedAt;
  return (
    <CollapsibleRow
      testID="approve-shift-info-collapsible"
      startCollapsed
      leftSpacing="medium"
      iconColor="primary"
      icon="business-time"
      title="Approved Shift Info"
      noteColor="neutral"
      note={`${duration.humanize(
        paymentInfo?.billableDuration,
        "semi-compact-no-spaces"
      )} worked, Paid: ${currency.humanize(paymentInfo?.netPay)}`}
    >
      <ContentArea>
        <Stack size="slim">
          <Stack horizontal justifyContent="space-between">
            <Text>Gig Name</Text>
            <Text numberOfLines={1} weight="semibold">
              {gig?.name}
            </Text>
          </Stack>
          <Stack horizontal justifyContent="space-between">
            <Text>Start Time</Text>
            <Text numberOfLines={1} weight="semibold">
              {time.humanize(startsAt, "dateTimeCompact")}
            </Text>
          </Stack>
          <Stack horizontal justifyContent="space-between">
            <Text>End Time</Text>
            <Text numberOfLines={1} weight="semibold">
              {time.humanize(endsAt, "dateTimeCompact")}
            </Text>
          </Stack>
          <Stack horizontal justifyContent="space-between">
            <Text>Time Worked</Text>
            <Text numberOfLines={1} weight="semibold">
              {duration.humanize(
                paymentInfo?.billableDuration,
                "semi-compact-no-spaces"
              )}
            </Text>
          </Stack>
          <Stack horizontal justifyContent="space-between">
            <Text>Hourly Pay</Text>
            <Text numberOfLines={1} weight="semibold">
              {currency?.humanize(
                getBasePay(
                  paymentInfo?.basePay,
                  paymentInfo?.totalServiceFee?.amount
                )
              )}
            </Text>
          </Stack>
          {paymentInfo?.additionalPay &&
            paymentInfo?.additionalPay !== "0 USD" && (
              <Stack horizontal justifyContent="space-between">
                <Text>Additional Payment</Text>
                <Text numberOfLines={1} weight="semibold">
                  {currency?.humanize(paymentInfo?.additionalPay)}
                </Text>
              </Stack>
            )}
          <Stack horizontal justifyContent="space-between">
            <Text weight="semibold">Total Amount Paid</Text>
            <Text numberOfLines={1} weight="semibold">
              {currency?.humanize(paymentInfo?.netPay)}
            </Text>
          </Stack>
        </Stack>
      </ContentArea>
      <Divider />
      <ContentArea>
        <Surface variant="outline">
          <TabLayout
            active={activeTab}
            onSelectTab={(tab) => setActiveTab(tab)}
            tabs={[
              {
                key: "payment",
                label: "PAYMENT",
                renderContent: () => (
                  <WorkerGigReceipt
                    paymentInfo={paymentInfo}
                    currentState={currentState}
                  />
                )
              },
              {
                key: "timesheet",
                label: "TIMESHEET",
                renderContent: () => (
                  <>
                    {timesheet?.paymentStyle !== "CALCULATED" && (
                      <>
                        <ContentArea>
                          <HighlightedReminder
                            icon="circle-exclamation"
                            header="This Requester has opted to pay you without a Timesheet."
                          />
                        </ContentArea>
                        <Divider />
                        <ContentArea>
                          <Stack
                            horizontal
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text>Time Worked</Text>
                            <Text>
                              {duration.humanize(
                                timesheet?.totalDurationWorked,
                                "semi-compact-no-spaces"
                              )}
                            </Text>
                          </Stack>
                        </ContentArea>
                      </>
                    )}
                    {timesheet?.paymentStyle === "CALCULATED" && (
                      <ContentArea>
                        <TimesheetList
                          fragmentRef={timesheet}
                          variant="worker"
                          workerEta={null}
                          endsAt={null}
                        />
                      </ContentArea>
                    )}
                  </>
                )
              }
            ]}
          />
        </Surface>
      </ContentArea>
    </CollapsibleRow>
  );
}

export default createRelayFragmentContainer<WorkerShiftInfoCollapsible_engagement$key>(
  graphql`
    fragment WorkerShiftInfoCollapsible_engagement on Engagement {
      currentState {
        name
      }
      paymentInfo {
        billableDuration
        additionalPay
        totalServiceFee {
          amount
        }
        serviceFees {
          hourlyRate
          amount
          feeType
        }
        basePay
        netPay
        payRate
      }
      gig {
        name
        timezone
      }
      timesheet(variant: FINAL) {
        paymentStyle
        totalDurationWorked
        states(
          first: 50
          overridden: true
          orderBy: [{ transitionedAt: { direction: ASC } }]
        ) {
          edges {
            node {
              action
              transitionedAt
            }
          }
        }
        ...TimesheetList_timesheet @arguments(overridden: true)
      }
    }
  `,
  WorkerShiftInfoCollapsible
);

function getBasePay(basePay?: string, serviceFee?: string) {
  if (!basePay || !serviceFee) return basePay ?? "0 USD";
  const base = Number(basePay.replace(" USD", ""));
  const fee = Number(serviceFee.replace(" USD", ""));
  return `${base - fee} USD`;
}
