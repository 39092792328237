/**
 * @generated SignedSource<<c49f5e4ef11c018424fd7ea12410dd74>>
 * @relayHash a5ec216c8456e2c46e44af58bedf1de2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:p7e4hO61lhag3ihVuAiVW_BjVcjT-0ZHR3ebkRODnX8

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type gigSeriesOfferedScreenLegacyQuery$variables = {
  engagementsQuery: string;
  seriesId: string;
};
export type gigSeriesOfferedScreenLegacyQuery$data = {
  readonly series: {
    readonly gigType?: GigType | null | undefined;
    readonly nextEngagement?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly estimatedPayment: {
            readonly netPay: string;
          } | null | undefined;
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionHeader_availableGig" | "ShiftInfoCollapsable_engagement" | "engagementDetailsHeader_engagement">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly payRate?: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"gigDescription_gigLike" | "seriesSummaryCard_series">;
  } | null | undefined;
};
export type gigSeriesOfferedScreenLegacyQuery = {
  response: gigSeriesOfferedScreenLegacyQuery$data;
  variables: gigSeriesOfferedScreenLegacyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "seriesId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "seriesId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v6 = [
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "engagementsQuery"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Place",
  "kind": "LinkedField",
  "name": "place",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = [
  (v17/*: any*/),
  (v10/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v20 = [
  (v19/*: any*/),
  (v10/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptsTips",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "negotiable",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paySchedule",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hourlyRate",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feeType",
  "storageKey": null
},
v33 = [
  (v4/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPay",
    "storageKey": null
  },
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "WorkerServiceFee",
    "kind": "LinkedField",
    "name": "serviceFees",
    "plural": true,
    "selections": [
      (v30/*: any*/),
      (v31/*: any*/),
      (v32/*: any*/)
    ],
    "storageKey": null
  }
],
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v36 = [
  (v17/*: any*/),
  (v35/*: any*/),
  (v10/*: any*/)
],
v37 = [
  (v11/*: any*/)
],
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v39 = [
  (v10/*: any*/)
],
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "GigPosition",
  "kind": "LinkedField",
  "name": "position",
  "plural": false,
  "selections": (v39/*: any*/),
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v42 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v43 = [
  (v42/*: any*/)
],
v44 = {
  "alias": null,
  "args": (v43/*: any*/),
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": (v37/*: any*/),
  "storageKey": "reviewsReceived(first:0)"
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v46 = {
  "kind": "InlineFragment",
  "selections": (v39/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v47 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v45/*: any*/),
    (v46/*: any*/)
  ],
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastInitial",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        (v50/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street1",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street2",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "administrativeArea1",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": (v43/*: any*/),
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "unmetQualifications",
  "plural": false,
  "selections": (v37/*: any*/),
  "storageKey": "unmetQualifications(first:0)"
},
v61 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": "source",
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v63 = [
  (v45/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "gigSeriesOfferedScreenLegacyQuery",
    "selections": [
      {
        "alias": "series",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "gigDescription_gigLike"
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "engagementsQuery",
                    "variableName": "engagementsQuery"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "seriesSummaryCard_series"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "nextEngagement",
                "args": (v6/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ShiftDescriptionHeader_availableGig"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "engagementDetailsHeader_engagement"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ShiftInfoCollapsable_engagement"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "gigSeriesOfferedScreenLegacyQuery",
    "selections": [
      {
        "alias": "series",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasMultipleLocations",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "GigSeriesSkillsConnection",
                "kind": "LinkedField",
                "name": "skills",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeriesSkillsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeriesSkill",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "skill",
                            "plural": false,
                            "selections": (v18/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "skills(first:10)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GigCategory",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": (v20/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GigPosition",
                "kind": "LinkedField",
                "name": "position",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              {
                "alias": "nextOffered",
                "args": (v6/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigSeries",
                                "kind": "LinkedField",
                                "name": "gigSeries",
                                "plural": false,
                                "selections": [
                                  (v23/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Pickup",
                                "kind": "LinkedField",
                                "name": "pickup",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "eligible",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v26/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Place",
                                "kind": "LinkedField",
                                "name": "place",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  (v13/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "placeId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": (v18/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": (v33/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "paymentInfo",
                            "plural": false,
                            "selections": (v33/*: any*/),
                            "storageKey": null
                          },
                          (v34/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementBid",
                            "kind": "LinkedField",
                            "name": "currentBid",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimatedAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "expectedDuration",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "note",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": "nextEngagement",
                "args": (v6/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "transitionedBy",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "transitionedAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v21/*: any*/),
                              (v25/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigState",
                                "kind": "LinkedField",
                                "name": "currentState",
                                "plural": false,
                                "selections": (v18/*: any*/),
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": (v36/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "conversationRule",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShiftGroupConversation",
                                "kind": "LinkedField",
                                "name": "conversation",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "last",
                                        "value": 1
                                      }
                                    ],
                                    "concreteType": "UserMessagesConnection",
                                    "kind": "LinkedField",
                                    "name": "messages",
                                    "plural": false,
                                    "selections": (v37/*: any*/),
                                    "storageKey": "messages(last:1)"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ConversationParticipant",
                                    "kind": "LinkedField",
                                    "name": "participant",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserMessageStats",
                                        "kind": "LinkedField",
                                        "name": "messageStats",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "unreadCount",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationRequester",
                                "kind": "LinkedField",
                                "name": "primaryContact",
                                "plural": false,
                                "selections": (v20/*: any*/),
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationLocation",
                                "kind": "LinkedField",
                                "name": "organizationLocation",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Place",
                                    "kind": "LinkedField",
                                    "name": "place",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v10/*: any*/),
                                      (v27/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      (v5/*: any*/)
                                    ],
                                    "concreteType": "OrganizationParkingLocationsConnection",
                                    "kind": "LinkedField",
                                    "name": "parkingLocations",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OrganizationParkingLocationsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrganizationParkingLocation",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Place",
                                                "kind": "LinkedField",
                                                "name": "place",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v14/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "workerParkingDistance",
                                                "storageKey": null
                                              },
                                              (v27/*: any*/),
                                              (v10/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "parkingLocations(first:1)"
                                  }
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Requester",
                                "kind": "LinkedField",
                                "name": "requester",
                                "plural": false,
                                "selections": (v20/*: any*/),
                                "storageKey": null
                              },
                              (v38/*: any*/),
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigSeries",
                                "kind": "LinkedField",
                                "name": "gigSeries",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v17/*: any*/),
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "requiredPermissions",
                                "storageKey": null
                              },
                              (v40/*: any*/),
                              (v22/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "organization",
                                    "plural": false,
                                    "selections": [
                                      (v41/*: any*/),
                                      (v44/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrganizationRequester",
                                    "kind": "LinkedField",
                                    "name": "primaryContact",
                                    "plural": false,
                                    "selections": [
                                      (v41/*: any*/),
                                      (v47/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Requester",
                                        "kind": "LinkedField",
                                        "name": "requester",
                                        "plural": false,
                                        "selections": [
                                          (v41/*: any*/),
                                          (v44/*: any*/),
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Requester",
                                    "kind": "LinkedField",
                                    "name": "requester",
                                    "plural": false,
                                    "selections": [
                                      (v47/*: any*/),
                                      (v41/*: any*/),
                                      (v44/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Requester",
                                        "kind": "LinkedField",
                                        "name": "requester",
                                        "plural": false,
                                        "selections": [
                                          (v48/*: any*/),
                                          (v49/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Organization",
                                            "kind": "LinkedField",
                                            "name": "primaryOrganization",
                                            "plural": false,
                                            "selections": [
                                              (v10/*: any*/),
                                              (v17/*: any*/),
                                              (v35/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Gig",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Requester",
                                        "kind": "LinkedField",
                                        "name": "requester",
                                        "plural": false,
                                        "selections": [
                                          (v48/*: any*/),
                                          (v49/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Organization",
                                            "kind": "LinkedField",
                                            "name": "primaryOrganization",
                                            "plural": false,
                                            "selections": (v36/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "GigSeries",
                                    "abstractKey": null
                                  }
                                ],
                                "type": "GigLike",
                                "abstractKey": "__isGigLike"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "FINAL"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paid",
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"FINAL\")"
                          },
                          {
                            "alias": "workerTimesheet",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "WORKER"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "editable",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalDurationWorked",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementPaymentInfo",
                                "kind": "LinkedField",
                                "name": "paymentInfo",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": "timesheet(variant:\"WORKER\")"
                          },
                          {
                            "alias": "systemTimesheet",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "SYSTEM"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "scheduledCount",
                                "args": [
                                  (v42/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE name = SCHEDULED"
                                  }
                                ],
                                "concreteType": "EngagementStateOrOverridesConnection",
                                "kind": "LinkedField",
                                "name": "states",
                                "plural": false,
                                "selections": (v37/*: any*/),
                                "storageKey": "states(first:0,query:\"WHERE name = SCHEDULED\")"
                              },
                              {
                                "alias": "startedCount",
                                "args": [
                                  (v42/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE action = START"
                                  }
                                ],
                                "concreteType": "EngagementStateOrOverridesConnection",
                                "kind": "LinkedField",
                                "name": "states",
                                "plural": false,
                                "selections": (v37/*: any*/),
                                "storageKey": "states(first:0,query:\"WHERE action = START\")"
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"SYSTEM\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CancellationReason",
                            "kind": "LinkedField",
                            "name": "cancellationReason",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authoredBy",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reasonType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 50
                                  }
                                ],
                                "concreteType": "CancellationReasonDisqualificationsConnection",
                                "kind": "LinkedField",
                                "name": "disqualifications",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CancellationReasonDisqualificationsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CancellationReasonDisqualification",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GigFieldItemDefinition",
                                            "kind": "LinkedField",
                                            "name": "gigFieldItemDefinition",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "label",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "GigField",
                                                "kind": "LinkedField",
                                                "name": "gigField",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "iconName",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              (v10/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "disqualifications(first:50)"
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableActions",
                            "storageKey": null
                          },
                          (v51/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contactNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementCapability",
                            "kind": "LinkedField",
                            "name": "capabilities",
                            "plural": true,
                            "selections": [
                              (v50/*: any*/),
                              (v52/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Worker",
                            "kind": "LinkedField",
                            "name": "worker",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "transportationMethod",
                                "storageKey": null
                              },
                              (v48/*: any*/),
                              (v53/*: any*/),
                              (v54/*: any*/),
                              (v55/*: any*/),
                              (v56/*: any*/),
                              (v57/*: any*/),
                              (v58/*: any*/),
                              (v59/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserEmail",
                                "kind": "LinkedField",
                                "name": "primaryEmail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "address",
                                    "storageKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WorkerLegalInformation",
                                "kind": "LinkedField",
                                "name": "legalInformation",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sendReportCopies",
                                    "storageKey": null
                                  },
                                  (v48/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "middleName",
                                    "storageKey": null
                                  },
                                  (v53/*: any*/),
                                  (v57/*: any*/),
                                  (v55/*: any*/),
                                  (v56/*: any*/),
                                  (v58/*: any*/),
                                  (v59/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "emailAddress",
                                    "storageKey": null
                                  },
                                  (v54/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "backgroundCheckOptIn",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "driverCheckOptIn",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "driversLicenseLast4",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "driversLicenseIssuer",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "drugScreenOptIn",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pspTosAccepted",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WorkerVerification",
                                "kind": "LinkedField",
                                "name": "verification",
                                "plural": false,
                                "selections": [
                                  (v52/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "VerificationReport",
                                    "kind": "LinkedField",
                                    "name": "reports",
                                    "plural": true,
                                    "selections": [
                                      (v50/*: any*/),
                                      (v52/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "statusDescription",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WorkerPayableAccount",
                                "kind": "LinkedField",
                                "name": "payableAccount",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "paymentMethodId",
                                    "storageKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "conversation",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v46/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v39/*: any*/),
                                "type": "OrganizationWorkerConversation",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v39/*: any*/),
                                "type": "EngagementConversation",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v39/*: any*/),
                                "type": "ShiftGroupConversation",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          (v34/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserTopic",
                            "kind": "LinkedField",
                            "name": "topic",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "canSendMessages",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementCommitment",
                            "kind": "LinkedField",
                            "name": "commitment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "expiresAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "response",
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workerEta",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigSeries",
                            "kind": "LinkedField",
                            "name": "gigSeries",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "paymentInfo",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "basePay",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WorkerServiceFee",
                                "kind": "LinkedField",
                                "name": "serviceFees",
                                "plural": true,
                                "selections": [
                                  (v32/*: any*/),
                                  (v30/*: any*/),
                                  (v31/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RemoteApplicationApplet",
                            "kind": "LinkedField",
                            "name": "applets",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "params",
                                "storageKey": null
                              },
                              (v45/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              (v5/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE name=SCHEDULED"
                              }
                            ],
                            "concreteType": "EngagementStateOrOverridesConnection",
                            "kind": "LinkedField",
                            "name": "states",
                            "plural": false,
                            "selections": (v37/*: any*/),
                            "storageKey": "states(first:1,query:\"WHERE name=SCHEDULED\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementReview",
                            "kind": "LinkedField",
                            "name": "reviewOfRequester",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rating",
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v10/*: any*/),
                                      (v38/*: any*/),
                                      (v40/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeries",
                                        "kind": "LinkedField",
                                        "name": "gigSeries",
                                        "plural": false,
                                        "selections": [
                                          (v60/*: any*/),
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v61/*: any*/)
                                ],
                                "type": "AvailableGigsEdge",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementState",
                                    "kind": "LinkedField",
                                    "name": "currentState",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "EngagementNegotiation",
                                        "kind": "LinkedField",
                                        "name": "negotiation",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementPaymentInfo",
                                    "kind": "LinkedField",
                                    "name": "estimatedPayment",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "gig",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeries",
                                        "kind": "LinkedField",
                                        "name": "gigSeries",
                                        "plural": false,
                                        "selections": [
                                          (v60/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v61/*: any*/)
                                ],
                                "type": "Engagement",
                                "abstractKey": null
                              }
                            ],
                            "type": "AvailableGigOrEngagement",
                            "abstractKey": "__isAvailableGigOrEngagement"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "estimatedMileage",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v51/*: any*/),
                      (v62/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": (v16/*: any*/),
                        "concreteType": "GigPhotosConnection",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigPhotosEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPhoto",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v63/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "photos(first:10)"
                      }
                    ],
                    "type": "Gig",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v62/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boosted",
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v51/*: any*/),
                      {
                        "alias": null,
                        "args": (v16/*: any*/),
                        "concreteType": "GigSeriesPhotosConnection",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigSeriesPhotosEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigSeriesPhoto",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v63/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "photos(first:10)"
                      }
                    ],
                    "type": "GigSeries",
                    "abstractKey": null
                  }
                ],
                "type": "GigLike",
                "abstractKey": "__isGigLike"
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:p7e4hO61lhag3ihVuAiVW_BjVcjT-0ZHR3ebkRODnX8",
    "metadata": {},
    "name": "gigSeriesOfferedScreenLegacyQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f7d4e1f651d2dac828d852a1cc0f844c";

export default node;
