/**
 * @generated SignedSource<<a3332d64648ca4ce7964cd9c6045b76d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTimesheetTimes_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly id: string;
  readonly startsAt: string | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentType": "EditTimesheetTimes_engagement";
};
export type EditTimesheetTimes_engagement$key = {
  readonly " $data"?: EditTimesheetTimes_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTimesheetTimes_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTimesheetTimes_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "0f31485aedb80fff12df42cbee72281a";

export default node;
