import { Button, Card, ContentArea, Spacer, Text } from "@gigsmart/atorasu";
import { confirmPrompt } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";

import type { EditTimesheetTimeWorkedCard_engagement$key } from "./__generated__/EditTimesheetTimeWorkedCard_engagement.graphql";
import EditTimesheetTimes from "./edit/EditTimesheetTimes";
import type { timesheetValue_timesheet$key } from "./edit/__generated__/timesheetValue_timesheet.graphql";

interface Props {
  onRemoveTimeWorked?: () => void;
  allowReset?: boolean;
  title: string;
  minimumPaidGigDuration?: string | null;
  breaks?: number[] | null;
  timesheetRef?: timesheetValue_timesheet$key | null;
}

export const EditTimesheetTimeWorkedCard = ({
  onRemoveTimeWorked,
  title,
  allowReset,
  timesheetRef,
  minimumPaidGigDuration,
  breaks,
  timezone,
  result
}: FragmentContainerInnerComponentProps<
  EditTimesheetTimeWorkedCard_engagement$key,
  Props
>) => {
  const handleRemoveTimeWorked = () => {
    confirmPrompt({
      title: "Are you sure you want to Remove Time Worked?",
      subTitle:
        "By removing time worked, you are indicating you did not work on this Shift.",
      yesLabel: "Remove Time Worked",
      yesLabelStyle: "destructive",
      cancelLabel: "Cancel",
      onDo: onRemoveTimeWorked
    });
  };

  return (
    <Card
      testID="edit-timesheet-time-worked-card"
      eventTargetName="Edit Timesheet Time Worked Card"
    >
      <ContentArea>
        <Text weight="bold" testID="edit-timesheet-time-worked-title">
          {title}
        </Text>
        <Spacer />
        <Text>
          All times are shown in the Shift's local time:{" "}
          {DateTime.local()
            .setZone(timezone ?? undefined)
            .toFormat("ZZZZ")}
        </Text>
        <Spacer />
        <EditTimesheetTimes
          engagementRef={result}
          timesheetRef={timesheetRef}
          breaks={breaks}
          minimumPaidGigDuration={minimumPaidGigDuration}
        >
          {allowReset && (
            <Button
              testID="remove-time-worked-button"
              outline
              icon="redo"
              label="Remove Time Worked"
              onPress={handleRemoveTimeWorked}
            />
          )}
        </EditTimesheetTimes>
      </ContentArea>
    </Card>
  );
};

export default createRelayFragmentContainer<
  EditTimesheetTimeWorkedCard_engagement$key,
  Props
>(
  graphql`
    fragment EditTimesheetTimeWorkedCard_engagement on Engagement {
      ...EditTimesheetTimes_engagement
      timezone
    }
  `,
  EditTimesheetTimeWorkedCard
);
