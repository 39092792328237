import { Button, ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  FormSubmit,
  FormValues,
  type ValueObject
} from "@gigsmart/fomu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import EditMileage from "@gigsmart/seibutsu/engagement/EditMileage";
import React, { type ReactNode, useState } from "react";
import CollapsbileGigSchedule from "../gig/CollapsibleGigSchedule";
import EditTimesheetBreaks from "./EditTimesheetBreaks";
import EditTimesheetTimeWorkedCard from "./EditTimesheetTimeWorkedCard";
import type { EditTimesheetForm_engagement$key } from "./__generated__/EditTimesheetForm_engagement.graphql";
import {
  type EngagementTimesheetState,
  useTimesheetInitialValues
} from "./edit/timesheet-value";

interface Props {
  create: boolean;
  title: string;
  submitLabel: string;
  onSubmit: (
    values: ValueObject,
    breaks: number[],
    overrides: EngagementTimesheetState[],
    originalStartsAt: string | undefined,
    done: () => void
  ) => void;
  onRemoveTimeWorked?: () => void;
  fragmentRef?: EditTimesheetForm_engagement$key | null | undefined;
  appVariant: "worker" | "requester";
  extraActions?: ReactNode;
}

const EditTimesheetForm = ({
  create,
  title,
  submitLabel,
  onSubmit,
  onRemoveTimeWorked,
  fragmentRef,
  appVariant,
  extraActions
}: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment EditTimesheetForm_engagement on Engagement
      @argumentDefinitions(
        variant: { type: "EngagementTimesheetVariant" }
        overridden: { type: "Boolean" }
      ) {
        ...EditTimesheetTimeWorkedCard_engagement
        gig {
          gigType
          estimatedMileage
          ...CollapsibleGigSchedule_gig
        }
        startsAt
        endsAt
        timezone
        worker {
          displayName
        }
        systemTimesheet: timesheet(variant: SYSTEM) {
          ...timesheetValue_timesheet @arguments(overridden: $overridden)
        }
        timesheetVariant: timesheet(variant: $variant) {
          ...timesheetValue_timesheet @arguments(overridden: $overridden)
        }
      }
    `,
    fragmentRef ?? null
  );

  const timesheet = engagement?.timesheetVariant ?? engagement?.systemTimesheet;
  const { timesheetStates, initialValues, initialBreaks, originalStartsAt } =
    useTimesheetInitialValues(
      timesheet,
      engagement?.startsAt,
      engagement?.endsAt,
      engagement?.timezone,
      create
    );

  const [breaks, setBreaks] = useState(initialBreaks);
  const handleSubmit: FomuSubmitFn = ({ values }, done) => {
    onSubmit(values, breaks, timesheetStates, originalStartsAt, done);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      checkRemovedFields
    >
      <Stack>
        <CollapsbileGigSchedule fragmentRef={engagement?.gig} />
        {engagement?.gig?.estimatedMileage && (
          <EditMileage
            workerName={engagement?.worker?.displayName}
            appVariant={appVariant}
          />
        )}
        <EditTimesheetTimeWorkedCard
          title={title}
          fragmentRef={engagement}
          timesheetRef={timesheet}
          breaks={breaks}
          onRemoveTimeWorked={onRemoveTimeWorked}
          allowReset={!create && appVariant === "requester"}
        />
        <EditTimesheetBreaks
          breaks={breaks}
          setBreaks={setBreaks}
          volunteer={engagement?.gig?.gigType === "VOLUNTEER"}
          initialValues={initialValues}
        />
        <FormValues>
          {({ values }) => (
            <FormSubmit>
              {({ submit, invalid, submitting }) => {
                const isEquals = checkEquals(
                  breaks,
                  initialValues,
                  values ?? {}
                );
                return (
                  <ContentArea size="none">
                    <Stack>
                      {extraActions}
                      <Button
                        testID="edit-timesheet-form-submit"
                        label={submitLabel}
                        disabled={
                          create ? invalid : invalid || isEquals || submitting
                        }
                        onPress={submit}
                      />
                    </Stack>
                  </ContentArea>
                );
              }}
            </FormSubmit>
          )}
        </FormValues>
      </Stack>
      <Spacer />
    </Form>
  );
};

export default EditTimesheetForm;

function checkEquals(
  breaks: number[],
  initialValues: ValueObject,
  values: ValueObject
) {
  return (
    initialValues.mileage === values.mileage &&
    initialValues.includeBreaks === values.includeBreaks &&
    checkDateEquals(initialValues, values, "startTimeDate") &&
    checkDateEquals(initialValues, values, "endTimeDate") &&
    initialValues.startTimeTime === values.startTimeTime &&
    initialValues.startTimeAmpm === values.startTimeAmpm &&
    initialValues.endTimeTime === values.endTimeTime &&
    initialValues.endTimeAmpm === values.endTimeAmpm &&
    (values.includeBreaks ? breaks : []).every(
      (n) =>
        checkDateEquals(initialValues, values, `break${n}StartDate`) &&
        checkDateEquals(initialValues, values, `break${n}EndDate`) &&
        initialValues[`break${n}StartTime`] === values[`break${n}StartTime`] &&
        initialValues[`break${n}StartAmpm`] === values[`break${n}StartAmpm`] &&
        initialValues[`break${n}EndTime`] === values[`break${n}EndTime`] &&
        initialValues[`break${n}EndAmpm`] === values[`break${n}EndAmpm`]
    )
  );
}

function checkDateEquals(
  initialValues: ValueObject,
  values: ValueObject,
  attr: string
) {
  const initial = initialValues[attr];
  const curr = values[attr];
  return !!curr && initial?.equals(curr);
}
