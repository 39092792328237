import type { DateTime } from "luxon";
import React from "react";
import { Platform, View } from "react-native";
import ContentRow from "../atoms/ContentRow";
import IconText from "../atoms/IconText";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style";

import StyledPicker from "@gigsmart/katana/input/styled-picker";
// TODO: Remove katana dependency
import StyledTextInput from "@gigsmart/katana/input/styled-text-input";

const OPTIONS_AM_PM = ["AM", "PM"];

interface Props {
  label: string;
  time?: string;
  date?: DateTime;
  availableDates?: DateTime[];
  ampm?: string;
  zIndex?: number;
  onChangeTime?: (time: string) => void;
  onChangeAmpm?: (ampm: string) => void;
  onChangeDate?: (date: DateTime) => void;
  error?: string | null;
  inputTestId: string;
}

const toValue = (date?: DateTime) => {
  const result = date ? date.toFormat("ccc D") : "";
  return result;
};

const DateTimeInputRow = ({
  label,
  time,
  ampm,
  date,
  availableDates,
  zIndex = 1,
  onChangeTime,
  onChangeAmpm,
  onChangeDate,
  error,
  inputTestId
}: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    inputContainerInline: { alignItems: "flex-end" },
    inputContainerWrap: {
      width: "100%",
      paddingTop: getUnits(2)
    },

    inputHm: { width: 70 },
    ampmPicker: {
      top: 0,
      width: 90,
      zIndex: zIndex + 20
    },
    dayPicker: { top: 0, flex: 1 },
    errorText: {
      width: getUnits(38),
      marginTop: getUnits(1),
      zIndex: -1
    }
  }));

  const handleChangeDate = (newDate: string) => {
    const date = availableDates?.find((d) => toValue(d) === newDate);
    if (date) onChangeDate?.(date);
  };

  return (
    <View style={{ zIndex: zIndex + 2, flex: 1 }}>
      <ContentRow verticalSize="compact" variant="none" fill>
        <Row gap="compact" fill>
          <Column fill>
            <IconText
              fill
              size="small"
              color="black"
              iconColor="primary"
              icon="clock"
              spacing="compact"
              numberOfLines={2}
            >
              {label}
            </IconText>
          </Column>
          <View
            style={
              availableDates
                ? styles.inputContainerWrap
                : styles.inputContainerInline
            }
          >
            <Stack horizontal size="medium">
              {!!availableDates && (
                <StyledPicker
                  eventTargetName="Input Date Picker"
                  testID={`${inputTestId}-date-picker-input`}
                  style={styles.dayPicker}
                  value={toValue(date)}
                  options={availableDates.map(toValue)}
                  onChangeText={handleChangeDate}
                  placeholder="Select Date"
                />
              )}
              <View>
                <Stack horizontal size="medium">
                  <StyledTextInput
                    legacyImplementation
                    containerStyle={styles.inputHm}
                    eventTargetName="Input Hours/Minutes"
                    testID={`${inputTestId}-time-picker-hm-input`}
                    value={time}
                    mask="time"
                    placeholder="-- : --"
                    onChangeText={onChangeTime}
                    keyboardType={
                      Platform.OS === "web" ? "default" : "number-pad"
                    }
                  />
                  <StyledPicker
                    legacyImplementation
                    style={styles.ampmPicker}
                    eventTargetName="Input AM/PM Picker"
                    testID={`${inputTestId}-time-picker-ampm-input`}
                    value={ampm}
                    placeholder="--"
                    options={OPTIONS_AM_PM}
                    onChangeText={onChangeAmpm}
                    dropdownPosition="bottom"
                  />
                </Stack>
                {!!error && (
                  <Text
                    color="error"
                    style={styles.errorText}
                    eventTargetName="DateTime Input Error Text"
                  >
                    {error}
                  </Text>
                )}
              </View>
            </Stack>
          </View>
        </Row>
      </ContentRow>
    </View>
  );
};

export default DateTimeInputRow;
