import {
  ContentArea,
  ContentRow,
  Divider,
  HighlightedReminder,
  InlineButton,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import { TabbedModal } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { useState } from "react";
import TimesheetList from "../../engagement/TimesheetList";
import type { ApprovedTimesheetModalRow_approved_timesheet$key } from "./__generated__/ApprovedTimesheetModalRow_approved_timesheet.graphql";
import type { ApprovedTimesheetModalRow_system_timesheet$key } from "./__generated__/ApprovedTimesheetModalRow_system_timesheet.graphql";

interface Props {
  timeWorked?: string | null;
  systemTimesheetRef: ApprovedTimesheetModalRow_system_timesheet$key | null;
  approvedTimesheetRef: ApprovedTimesheetModalRow_approved_timesheet$key | null;
}

export default function ApprovedTimesheetModalRow({
  timeWorked,
  systemTimesheetRef,
  approvedTimesheetRef
}: Props) {
  const systemTimesheet = useRelayFragment(
    graphql`
      fragment ApprovedTimesheetModalRow_system_timesheet on EngagementTimesheet {
        ...TimesheetList_timesheet @arguments(overridden: true)
      }
    `,
    systemTimesheetRef
  );
  const approvedTimesheet = useRelayFragment(
    graphql`
      fragment ApprovedTimesheetModalRow_approved_timesheet on EngagementTimesheet {
        paymentStyle
        totalDurationWorked
        ...TimesheetList_timesheet @arguments(overridden: true)
      }
    `,
    approvedTimesheetRef
  );

  const [showModal, setShowModal] = useState(false);
  const tabs = [
    {
      key: "approved",
      label: "APPROVED",
      Contents: (
        <ContentArea>
          <Stack>
            <Text>
              This is the Timesheet the Requester approved. Your approved
              payment is based on this Timesheet.
            </Text>
            {approvedTimesheet?.paymentStyle === "CALCULATED" ? (
              <TimesheetList
                fragmentRef={approvedTimesheet}
                variant="worker"
                workerEta={null}
                endsAt={null}
              />
            ) : (
              <>
                <HighlightedReminder
                  icon="circle-exclamation"
                  header="This Requester opted to pay you without a Timesheet."
                />
                <Spacer />
                <Divider />
                <ContentArea>
                  <Stack horizontal justifyContent="space-between">
                    <Text>Time Worked</Text>
                    <Text>
                      {duration.humanize(
                        approvedTimesheet?.totalDurationWorked,
                        "semi-compact-no-spaces"
                      )}
                    </Text>
                  </Stack>
                </ContentArea>
                <Divider />
              </>
            )}
          </Stack>
        </ContentArea>
      )
    },
    {
      key: "system",
      label: "SYSTEM",
      Contents: (
        <ContentArea>
          <Stack>
            <Text>The following times were recorded by the system.</Text>
            <TimesheetList
              fragmentRef={systemTimesheet}
              variant="worker"
              workerEta={null}
              endsAt={null}
            />
          </Stack>
        </ContentArea>
      )
    }
  ];
  return (
    <Surface>
      <ContentRow>
        <Row justifyContent="space-between" alignItems="center" fill>
          <Text>
            <Text weight="semibold">Approved Timesheet:</Text>{" "}
            <Text weight="semibold" color="neutral">
              {duration.humanize(timeWorked, "semi-compact-no-spaces")}
            </Text>
          </Text>
          <InlineButton
            label="View"
            onPress={() => setShowModal(true)}
            testID="view-timesheet-btn"
          />
        </Row>
      </ContentRow>
      <TabbedModal
        testID="approved-timesheet-modal"
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        tabs={tabs}
        title="Approved Timesheet"
      />
    </Surface>
  );
}
