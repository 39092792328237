/**
 * @generated SignedSource<<c22f242775f49d687a56d867ba3d9eaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditTimesheetForm_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly gig: {
    readonly estimatedMileage: number | null | undefined;
    readonly gigType: GigType | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CollapsibleGigSchedule_gig">;
  };
  readonly startsAt: string | null | undefined;
  readonly systemTimesheet: {
    readonly " $fragmentSpreads": FragmentRefs<"timesheetValue_timesheet">;
  } | null | undefined;
  readonly timesheetVariant: {
    readonly " $fragmentSpreads": FragmentRefs<"timesheetValue_timesheet">;
  } | null | undefined;
  readonly timezone: string | null | undefined;
  readonly worker: {
    readonly displayName: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EditTimesheetTimeWorkedCard_engagement">;
  readonly " $fragmentType": "EditTimesheetForm_engagement";
};
export type EditTimesheetForm_engagement$key = {
  readonly " $data"?: EditTimesheetForm_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTimesheetForm_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "overridden",
        "variableName": "overridden"
      }
    ],
    "kind": "FragmentSpread",
    "name": "timesheetValue_timesheet"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "overridden"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "variant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTimesheetForm_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditTimesheetTimeWorkedCard_engagement"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedMileage",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollapsibleGigSchedule_gig"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "systemTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    },
    {
      "alias": "timesheetVariant",
      "args": [
        {
          "kind": "Variable",
          "name": "variant",
          "variableName": "variant"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "020ccb77c15eac422d73b423f1dfd670";

export default node;
