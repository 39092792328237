import {
  ContentArea,
  DateTimeInputRow,
  Divider,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import { KatanaSwitch } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { findLast } from "lodash";
import { DateTime } from "luxon";
import React, { type ReactNode, useMemo, useState } from "react";
import {
  createEndTimeGigDurationValidator,
  createEndTimeValidator,
  createStartTimeValidator,
  useTimesheetInputFields
} from "../timesheetEditHelpers";
import type { EditTimesheetTimes_engagement$key } from "./__generated__/EditTimesheetTimes_engagement.graphql";
import type { timesheetValue_timesheet$key } from "./__generated__/timesheetValue_timesheet.graphql";
import { useTimesheetData } from "./timesheet-value";

type Props = {
  create?: boolean;
  engagementRef?: EditTimesheetTimes_engagement$key | null;
  timesheetRef?: timesheetValue_timesheet$key | null;
  minimumPaidGigDuration?: string | null;
  breaks?: number[] | null;
  prefix?: ReactNode;
  children?: ReactNode;
};

export default function EditTimesheetTimes({
  create,
  breaks,
  minimumPaidGigDuration,
  timesheetRef,
  engagementRef,
  prefix,
  children
}: Props) {
  const { appId } = useAppSettings();
  const {
    startsAt,
    endsAt,
    timezone: rawTz
  } = useRelayFragment(
    graphql`
      fragment EditTimesheetTimes_engagement on Engagement {
        id
        timezone
        startsAt
        endsAt
      }
    `,
    engagementRef ?? null
  ) ?? {};

  const timezone = rawTz || undefined;
  const {
    engagementStartTime,
    initialStartTime,
    initialEndTime,
    timesheetStates,
    initialBreaks
  } = useTimesheetData(timesheetRef, startsAt, endsAt, timezone, create);

  const isSameDay = initialStartTime.hasSame(initialEndTime, "day");
  const [showDate, setShowDate] = useState(!isSameDay);

  // Compute available dates/initial gig startTime
  const { availableDates } = useMemo(() => {
    const availableDates = [
      initialStartTime.setZone(timezone).startOf("day"),
      initialStartTime.plus({ days: 1 }).startOf("day")
    ];
    if (
      !initialStartTime.minus({ hours: 3 }).hasSame(initialStartTime, "day")
    ) {
      availableDates.unshift(initialStartTime.minus({ days: 1 }));
    }
    return { availableDates };
  }, [initialStartTime]);

  // Compute validators
  const { startTimeValidator, endTimeValidator } = useMemo(() => {
    // Fallback to system's
    const scheduledState = findLast(
      timesheetStates,
      (d) => d?.name === "SCHEDULED"
    );

    const relativeStartTime = (engagementStartTime ?? initialStartTime).setZone(
      timezone
    );
    let earliestStartTime = relativeStartTime
      .setZone(timezone)
      .minus({ hours: 3 });
    let showScheduledError = false;
    if (
      scheduledState?.transitionedAt &&
      DateTime.fromISO(scheduledState.transitionedAt) > earliestStartTime
    ) {
      showScheduledError = true;
      earliestStartTime = DateTime.fromISO(scheduledState.transitionedAt);
    }

    const startTimeValidator = createStartTimeValidator({
      earliestStartTime,
      dateTimeFieldName: "startTime",
      showScheduledError,
      breaks: breaks ?? initialBreaks,
      isRequester: appId === "requester",
      timezone
    });

    const endTimeValidator = [
      createEndTimeValidator({
        dateTimeFieldName: "startTime",
        breaks: breaks ?? initialBreaks,
        timezone
      }),
      createEndTimeGigDurationValidator({
        dateTimeFieldName: "endTime",
        minimumPaidGigDuration
      })
    ];

    return { startTimeValidator, endTimeValidator };
  }, [breaks, engagementStartTime, initialStartTime]);

  const startTimeProps = useTimesheetInputFields(
    "startTime",
    startTimeValidator
  );
  const endTimeProps = useTimesheetInputFields("endTime", endTimeValidator);

  return (
    <>
      <Divider />
      <DateTimeInputRow
        zIndex={20}
        {...startTimeProps}
        inputTestId="start-time"
        label="Start Time"
        availableDates={showDate ? availableDates : undefined}
      />
      <Divider />
      <DateTimeInputRow
        {...endTimeProps}
        inputTestId="end-time"
        label="End Time"
        availableDates={showDate ? availableDates : undefined}
      />
      <Divider />
      <Spacer />
      <ContentArea size="none" gap="standard">
        {prefix}
        <Row alignItems="center">
          <Text fill>View dates for Start and End Times</Text>
          <KatanaSwitch
            testID="edit-timesheet-time-worked-day-switch"
            disabled={!isSameDay}
            value={showDate}
            onValueChange={setShowDate}
          />
        </Row>
        {children}
      </ContentArea>
    </>
  );
}
