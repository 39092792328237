/**
 * @generated SignedSource<<f9af3a6ba19162112d5531cdd70402a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApprovedTimesheetModalRow_approved_timesheet$data = {
  readonly paymentStyle: EngagementTimesheetPaymentStyle;
  readonly totalDurationWorked: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  readonly " $fragmentType": "ApprovedTimesheetModalRow_approved_timesheet";
};
export type ApprovedTimesheetModalRow_approved_timesheet$key = {
  readonly " $data"?: ApprovedTimesheetModalRow_approved_timesheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovedTimesheetModalRow_approved_timesheet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovedTimesheetModalRow_approved_timesheet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDurationWorked",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "overridden",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "TimesheetList_timesheet"
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
};

(node as any).hash = "3cecbd18149fd827345e38e35d345017";

export default node;
