/**
 * @generated SignedSource<<d6da89fc4cef4645fc7a63b9f46af1f8>>
 * @relayHash 86c4c3d2d882fa52a1ecd5accf2f6d6a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ZQsFC9C0NLEx9oWjdj3U1RMqi20RWAhOPuk3KdafcCs

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type SetEngagementTimesheetInput = {
  additionalPayment?: string | null | undefined;
  complete?: boolean | null | undefined;
  createOverrides?: boolean | null | undefined;
  engagementId: string;
  estimatedDurationWorked?: string | null | undefined;
  estimatedMileage?: number | null | undefined;
  isApproved?: boolean | null | undefined;
  onBehalfOf?: UserInput | null | undefined;
  overrides?: ReadonlyArray<EngagementStateOverrideInput> | null | undefined;
  paid?: boolean | null | undefined;
  paymentStyle: EngagementTimesheetPaymentStyle;
  paymentType?: PaymentType | null | undefined;
  totalDurationWorked?: string | null | undefined;
  totalPaymentAmount?: string | null | undefined;
};
export type UserInput = {
  id: string;
  type: UserType;
};
export type EngagementStateOverrideInput = {
  action: EngagementStateAction;
  engagementStateId?: string | null | undefined;
  name: EngagementStateName;
  transitionedAt: string;
};
export type timesheetEditHooksResetMutation$variables = {
  input: SetEngagementTimesheetInput;
};
export type timesheetEditHooksResetMutation$data = {
  readonly setEngagementTimesheet: {
    readonly engagement: {
      readonly currentState: {
        readonly action: EngagementStateAction;
        readonly name: EngagementStateName;
      };
      readonly endsAt: string | null | undefined;
      readonly gig: {
        readonly estimatedMileage: number | null | undefined;
        readonly timezone: string | null | undefined;
      };
      readonly inactiveCancelStates: {
        readonly totalCount: number;
      } | null | undefined;
      readonly original: {
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly startedCount: {
          readonly totalCount: number;
        } | null | undefined;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly requesterTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly timesheet: {
        readonly id: string;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
      } | null | undefined;
      readonly workerTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ShiftRosterTable_engagement">;
    };
    readonly engagementTimesheet: {
      readonly additionalPayment: string | null | undefined;
      readonly editable: boolean;
      readonly isOverridden: boolean;
      readonly paymentStyle: EngagementTimesheetPaymentStyle;
      readonly totalDurationWorked: string | null | undefined;
    };
  } | null | undefined;
};
export type timesheetEditHooksResetMutation = {
  response: timesheetEditHooksResetMutation$data;
  variables: timesheetEditHooksResetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPayment",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "approvalDisposition": {
          "_isNull": true
        }
      }
    }
  ],
  "concreteType": "EngagementDisputesConnection",
  "kind": "LinkedField",
  "name": "disputes",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "disputes(first:0,where:{\"approvalDisposition\":{\"_isNull\":true}})"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementBillingInfo",
  "kind": "LinkedField",
  "name": "billingInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    },
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementBillingInfo",
  "kind": "LinkedField",
  "name": "estimatedBilling",
  "plural": false,
  "selections": [
    (v11/*: any*/)
  ],
  "storageKey": null
},
v21 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "TimesheetBillingInfo",
  "kind": "LinkedField",
  "name": "billingInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = [
  (v6/*: any*/),
  (v5/*: any*/),
  (v22/*: any*/)
],
v24 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "REQUESTER"
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v27 = {
  "alias": "inactiveCancelStates",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = INACTIVE_CANCEL"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v29 = {
  "alias": "startedCount",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v30 = {
  "kind": "Literal",
  "name": "overridden",
  "value": false
},
v31 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v32 = {
  "kind": "Literal",
  "name": "overridden",
  "value": true
},
v33 = [
  (v3/*: any*/),
  (v28/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v26/*: any*/),
  (v2/*: any*/),
  {
    "args": [
      (v32/*: any*/)
    ],
    "kind": "FragmentSpread",
    "name": "TimesheetList_timesheet"
  }
],
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v35 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "FINAL"
  }
],
v36 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentType",
    "storageKey": null
  },
  (v7/*: any*/)
],
v37 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v39 = [
  (v7/*: any*/)
],
v40 = {
  "kind": "InlineFragment",
  "selections": (v39/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v41 = {
  "transitionedAt": {
    "direction": "ASC"
  }
},
v42 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": (v41/*: any*/)
},
v43 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "name": {
      "_in": [
        "SCHEDULED",
        "WORKING",
        "ENDED",
        "PAUSED",
        "PENDING_TIMESHEET_APPROVAL"
      ]
    }
  }
},
v44 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v38/*: any*/),
          (v13/*: any*/),
          (v34/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v45 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v46 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    (v41/*: any*/)
  ]
},
v47 = [
  (v38/*: any*/),
  (v15/*: any*/),
  (v7/*: any*/)
],
v48 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v38/*: any*/),
          (v7/*: any*/),
          (v13/*: any*/),
          (v25/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v47/*: any*/),
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v49 = {
  "alias": null,
  "args": [
    (v45/*: any*/),
    (v46/*: any*/),
    (v32/*: any*/)
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v48/*: any*/),
  "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timesheetEditHooksResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "setEngagementTimesheet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementTimesheet",
            "kind": "LinkedField",
            "name": "engagementTimesheet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "ShiftRosterTable_engagement",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TimeWorked_engagement"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MoneyEarned_engagement"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EngagementActions_engagement"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RosterTimeCell_engagement"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RosterTimeBreakCell_engagement"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RosterActionsCell_engagement"
                  },
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WorkerAvatar_worker"
                      },
                      (v7/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v13/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": "systemTimesheet",
                    "args": (v21/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": "requesterTimesheet",
                    "args": (v24/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": "timesheet(variant:\"REQUESTER\")"
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v26/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v27/*: any*/),
              {
                "alias": "original",
                "args": (v21/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/),
                  (v29/*: any*/),
                  {
                    "args": [
                      (v30/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TimesheetList_timesheet"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v31/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v24/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timesheetEditHooksResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "setEngagementTimesheet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementTimesheet",
            "kind": "LinkedField",
            "name": "engagementTimesheet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v34/*: any*/),
                  (v25/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "finalTimesheet",
                "args": (v35/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": "finalSummary",
                "args": (v35/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v36/*: any*/),
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": "workerSummary",
                "args": (v31/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v36/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": null,
                "args": [
                  (v37/*: any*/)
                ],
                "concreteType": "EngagementPaymentsConnection",
                "kind": "LinkedField",
                "name": "payments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementPaymentsConnectionAggregate",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementPaymentsConnectionAggregateOperationSum",
                        "kind": "LinkedField",
                        "name": "sum",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payments(first:10)"
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v26/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Worker",
                "kind": "LinkedField",
                "name": "worker",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBlocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFavorite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "belongsToWorkerGroup",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePhoto",
                    "plural": false,
                    "selections": [
                      (v38/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v40/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v8/*: any*/)
                ],
                "concreteType": "WorkerLocationsConnection",
                "kind": "LinkedField",
                "name": "workerLocations",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": "workerLocations(first:0)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "conversation",
                "plural": false,
                "selections": [
                  (v38/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v39/*: any*/),
                    "type": "OrganizationWorkerConversation",
                    "abstractKey": null
                  },
                  (v40/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              {
                "alias": "systemTimesheet",
                "args": (v21/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": "rosterStates",
                    "args": [
                      (v37/*: any*/),
                      (v42/*: any*/),
                      (v43/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v44/*: any*/),
                    "storageKey": "states(first:10,orderBy:{\"transitionedAt\":{\"direction\":\"ASC\"}},where:{\"name\":{\"_in\":[\"SCHEDULED\",\"WORKING\",\"ENDED\",\"PAUSED\",\"PENDING_TIMESHEET_APPROVAL\"]}})"
                  },
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v24/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": "rosterStates",
                    "args": [
                      (v37/*: any*/),
                      (v42/*: any*/),
                      (v32/*: any*/),
                      (v43/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v44/*: any*/),
                    "storageKey": "states(first:10,orderBy:{\"transitionedAt\":{\"direction\":\"ASC\"}},overridden:true,where:{\"name\":{\"_in\":[\"SCHEDULED\",\"WORKING\",\"ENDED\",\"PAUSED\",\"PENDING_TIMESHEET_APPROVAL\"]}})"
                  },
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v22/*: any*/),
                  (v3/*: any*/),
                  (v28/*: any*/),
                  (v4/*: any*/),
                  (v26/*: any*/),
                  (v19/*: any*/),
                  (v49/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementReview",
                "kind": "LinkedField",
                "name": "reviewOfWorker",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              (v27/*: any*/),
              {
                "alias": "original",
                "args": (v21/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/),
                  (v29/*: any*/),
                  (v7/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v45/*: any*/),
                      (v46/*: any*/),
                      (v30/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v48/*: any*/),
                    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v31/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v28/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v19/*: any*/),
                  (v49/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ZQsFC9C0NLEx9oWjdj3U1RMqi20RWAhOPuk3KdafcCs",
    "metadata": {},
    "name": "timesheetEditHooksResetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0159d7109af130a54aa90a1165178504";

export default node;
