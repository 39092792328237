/**
 * @generated SignedSource<<b8a90a3115e05cabb56e97ab5d8ab9af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovedTimesheetModalRow_system_timesheet$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  readonly " $fragmentType": "ApprovedTimesheetModalRow_system_timesheet";
};
export type ApprovedTimesheetModalRow_system_timesheet$key = {
  readonly " $data"?: ApprovedTimesheetModalRow_system_timesheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovedTimesheetModalRow_system_timesheet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovedTimesheetModalRow_system_timesheet",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "overridden",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "TimesheetList_timesheet"
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
};

(node as any).hash = "7d36dc8fab06fe2228507c543dc9d5bb";

export default node;
