import React, { Fragment } from "react";
import { type ListRenderItem, Pressable } from "react-native";
import Animated from "react-native-reanimated";
import Skeleton, { SkeletonBone } from "../../atoms/Skeleton";
import { Column, Divider, Row } from "../../quarks";
import { CellBody } from "./CellBody";
import { useSyncScroll } from "./SyncScroll";
import { TableHeader } from "./TableHeader";
import type {
  GetCellHeightFn,
  NormalizedTableCol,
  TableKey,
  TableSortBy
} from "./TableView.helpers";

type Props<T> = {
  id: string;
  columns: NormalizedTableCol<T>[];
  data?: T[] | null;
  sortBy: TableSortBy<T>;
  onSort?: (key: TableKey<T>, dir: "asc" | "desc" | null) => void;
  getCellHeight: GetCellHeightFn;
  keyExtractor?: (item: T, idx: number) => string;
  onEndReached?: () => void;
  loading?: boolean;
  onRowPress?: (item: T) => void;
  headerVariant?: "default" | "shadow";
  scrollEnabled?: boolean;
};

export function SimpleTable<T>({
  id,
  columns,
  data,
  sortBy,
  onSort,
  getCellHeight,
  keyExtractor,
  onEndReached,
  loading,
  onRowPress,
  headerVariant,
  scrollEnabled
}: Props<T>) {
  const renderCell = (
    col: NormalizedTableCol<T>,
    colIndex: number,
    item: T,
    cellHeight: number,
    keyPrefix: string
  ) => {
    const { renderCell, CellComponent, attr } = col;
    const content = renderCell ? (
      renderCell(item)
    ) : CellComponent ? (
      <CellComponent data={item} />
    ) : attr ? (
      String(item[attr as keyof typeof item] ?? "—")
    ) : (
      "—"
    );

    return (
      <CellBody
        key={`${keyPrefix}.${colIndex}`}
        cellHeight={cellHeight}
        action={col.action}
        cellAlign={col.cellAlign}
        cellWidth={col.cellWidth}
      >
        {content}
      </CellBody>
    );
  };

  const renderItem: ListRenderItem<T> = ({ item, index }) => {
    const { cellHeight, onLayout } = getCellHeight(index);
    const keyPrefix = `${keyExtractor?.(item, index) ?? index}`;
    const node = (
      <Row onLayout={onLayout}>
        {columns.map((col, colIndex) =>
          renderCell(col, colIndex, item, cellHeight, keyPrefix)
        )}
      </Row>
    );

    return onRowPress ? (
      <Pressable onPress={() => onRowPress(item)}>{node}</Pressable>
    ) : (
      node
    );
  };

  const renderSkeletonFooter = (n: number) => {
    if (!loading) return null;

    return (
      <Skeleton loading>
        {Array.from({ length: n }, (_, rowIndex) => (
          <Fragment key={rowIndex}>
            <Separator />
            <Row>
              {columns.map((col, colIndex) => (
                <CellBody
                  key={`${rowIndex}.${colIndex}`}
                  cellWidth={col.cellWidth}
                  cellHeight={52}
                  action={col.action}
                >
                  <SkeletonBone lines={col.action ? 2 : 1} />
                </CellBody>
              ))}
            </Row>
          </Fragment>
        ))}
      </Skeleton>
    );
  };

  const { listRef, scrollHandler } = useSyncScroll<Animated.FlatList<T>>(id);
  return (
    <Column fill>
      <TableHeader
        variant={headerVariant}
        columns={columns}
        sortBy={sortBy}
        onSort={onSort}
      />
      <Animated.FlatList
        scrollEnabled={scrollEnabled}
        style={{ flex: 1 }}
        ref={listRef}
        renderItem={renderItem}
        data={data}
        ListFooterComponent={renderSkeletonFooter(data?.length ? 4 : 10)}
        ItemSeparatorComponent={Separator}
        keyExtractor={keyExtractor}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        onEndReached={onEndReached}
        bounces={false}
        scrollEventThrottle={16}
        onScroll={scrollHandler}
      />
    </Column>
  );
}

const Separator = () => <Divider />;
