/**
 * @generated SignedSource<<a53313ba52958856061181f8a6204a5d>>
 * @relayHash 814df2296fd0db1e7067a2829223ec4a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:BBK-5xFfKiMjmecZt_PVzVxh68XzLt9BXjWnoxfrUX4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type ApproveEngagementTimesheetInput = {
  timesheetId: string;
};
export type reviewTimesheetScreenApproveMutation$variables = {
  input: ApproveEngagementTimesheetInput;
};
export type reviewTimesheetScreenApproveMutation$data = {
  readonly approveEngagementTimesheet: {
    readonly engagement: {
      readonly currentState: {
        readonly action: EngagementStateAction;
        readonly name: EngagementStateName;
      };
      readonly endsAt: string | null | undefined;
      readonly gig: {
        readonly estimatedMileage: number | null | undefined;
        readonly timezone: string | null | undefined;
      };
      readonly inactiveCancelStates: {
        readonly totalCount: number;
      } | null | undefined;
      readonly original: {
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly startedCount: {
          readonly totalCount: number;
        } | null | undefined;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly requesterTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly workerTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
    };
    readonly engagementTimesheet: {
      readonly editable: boolean;
      readonly id: string;
      readonly isOverridden: boolean;
      readonly totalDurationWorked: string | null | undefined;
    };
  } | null | undefined;
};
export type reviewTimesheetScreenApproveMutation = {
  response: reviewTimesheetScreenApproveMutation$data;
  variables: reviewTimesheetScreenApproveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheet",
  "kind": "LinkedField",
  "name": "engagementTimesheet",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v12 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v14 = {
  "alias": "inactiveCancelStates",
  "args": [
    (v12/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = INACTIVE_CANCEL"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
},
v15 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v17 = {
  "alias": "startedCount",
  "args": [
    (v12/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v18 = {
  "kind": "Literal",
  "name": "overridden",
  "value": false
},
v19 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v21 = {
  "kind": "Literal",
  "name": "overridden",
  "value": true
},
v22 = [
  (v4/*: any*/),
  (v16/*: any*/),
  (v5/*: any*/),
  (v20/*: any*/),
  (v10/*: any*/),
  (v3/*: any*/),
  {
    "args": [
      (v21/*: any*/)
    ],
    "kind": "FragmentSpread",
    "name": "TimesheetList_timesheet"
  }
],
v23 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "REQUESTER"
  }
],
v24 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v25 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    {
      "transitionedAt": {
        "direction": "ASC"
      }
    }
  ]
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v27 = [
  (v26/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v2/*: any*/)
],
v28 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v26/*: any*/),
          (v2/*: any*/),
          (v9/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transitionedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v27/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v29 = [
  (v4/*: any*/),
  (v16/*: any*/),
  (v5/*: any*/),
  (v20/*: any*/),
  (v10/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": [
      (v24/*: any*/),
      (v25/*: any*/),
      (v21/*: any*/)
    ],
    "concreteType": "EngagementStateOrOverridesConnection",
    "kind": "LinkedField",
    "name": "states",
    "plural": false,
    "selections": (v28/*: any*/),
    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reviewTimesheetScreenApproveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "approveEngagementTimesheet",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": "original",
                "args": (v15/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v17/*: any*/),
                  {
                    "args": [
                      (v18/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TimesheetList_timesheet"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v19/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v22/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v23/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v22/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reviewTimesheetScreenApproveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "approveEngagementTimesheet",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": "original",
                "args": (v15/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v17/*: any*/),
                  (v2/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v18/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v28/*: any*/),
                    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v19/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v29/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v23/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v29/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:BBK-5xFfKiMjmecZt_PVzVxh68XzLt9BXjWnoxfrUX4",
    "metadata": {},
    "name": "reviewTimesheetScreenApproveMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "385a5643db71d2dc9a7541ecaa5a3992";

export default node;
