import {
  Column,
  Divider,
  Icon,
  ListRow,
  MaskedTextInput,
  Text
} from "@gigsmart/atorasu";
import {
  Validator,
  type ValueObject,
  useFormField,
  useFormValues
} from "@gigsmart/fomu";
import { numericKeyboardType } from "@gigsmart/katana";
import React from "react";
import { getDateTimeFromInputValues } from "../../engagement/timesheetEditHelpers";

interface Props {
  name?: string;
  variant?: "timesheet" | "totalTime";
}

export default function FomuMileageInput({
  name,
  variant = "timesheet"
}: Props) {
  const { value, setValue, triggerFocus } = useFormField<string>({
    name: name ?? "mileage",
    shouldShowErrors: () => true,
    validates: [
      Validator.minNumber({
        min: 0.1,
        message: "Must be greater than 0"
      }),
      Validator.maxNumber({
        max: 999.9,
        message: "Must be less than 1,000 miles"
      }),
      variant === "timesheet"
        ? maxMphTimesheetValidator()
        : maxMphTotalTimeValidator()
    ]
  });
  const { values: otherValues } = useFormValues();
  const mileageError = calculateMileageError(value, otherValues, variant);

  return (
    <>
      <Divider />
      <ListRow
        testID="mileage-input"
        left={<Icon name="truck-fast" size="small" variant="solid" />}
        right={
          <Column style={{ width: 100 }}>
            <MaskedTextInput
              testID={`${name}-mileage-input`}
              keyboardType={numericKeyboardType()}
              mask="oneDecimal"
              value={value ?? ""}
              onChangeText={setValue}
              onFocus={triggerFocus}
              rightAccessory={<Text>miles</Text>}
            />
          </Column>
        }
      >
        <Text>Total Mileage</Text>
        {mileageError && (
          <Text testID="mileage-input-error" variant="note" color="danger">
            {mileageError}
          </Text>
        )}
      </ListRow>
      <Divider />
    </>
  );
}

export function calculateMileageError(
  value?: string | null,
  otherFields?: ValueObject | null,
  variant?: "timesheet" | "totalTime"
) {
  if (!value) return null;
  if (variant === "timesheet") {
    const start = getDateTimeFromInputValues(
      otherFields?.startTimeTime,
      otherFields?.startTimeAmpm,
      otherFields?.startTimeDate
    );
    const end = getDateTimeFromInputValues(
      otherFields?.endTimeTime,
      otherFields?.endTimeAmpm,
      otherFields?.endTimeDate
    );
    const gigDuration = end.diff(start, "seconds").seconds / 3600;
    const mph = +value / gigDuration;
    if (mph > 75)
      return `Avg MPH: ${mph.toFixed(0)} (${value}mi/${gigDuration.toFixed(
        1
      )}hrs worked)`;
    return null;
  }
  if (variant === "totalTime") {
    const hours = otherFields?.hours;
    const minutes = otherFields?.minutes;
    const totalTime =
      Number.parseFloat(hours) + Number.parseFloat(minutes) / 60;
    const mph = +value / totalTime;
    if (mph > 75)
      return `Avg MPH: ${mph.toFixed(0)} (${value}mi/${totalTime.toFixed(
        1
      )}hrs worked)`;
    return null;
  }
  return null;
}

export const maxMphTimesheetValidator = Validator.create<
  { message?: string },
  string
>(({ message }) => (_, value, otherFields) => {
  const start = getDateTimeFromInputValues(
    otherFields?.get("startTimeTime"),
    otherFields?.get("startTimeAmpm"),
    otherFields?.get("startTimeDate")
  );
  const end = getDateTimeFromInputValues(
    otherFields?.get("endTimeTime"),
    otherFields?.get("endTimeAmpm"),
    otherFields?.get("endTimeDate")
  );
  const gigDuration = end.diff(start, "seconds").seconds / 3600;
  if (+value / gigDuration > 75)
    return [
      new Error(message ?? "Average speed during Shift cannot exceed 75 MPH.")
    ];
  return null;
});

export const maxMphTotalTimeValidator = Validator.create<
  {
    message?: string;
  },
  string
>(({ message }) => (_, value, otherFields) => {
  const hours = otherFields?.get("hours");
  const minutes = otherFields?.get("minutes");
  const totalTime = Number.parseFloat(hours) + Number.parseFloat(minutes) / 60;
  if (+value / totalTime > 75)
    return [
      new Error(message ?? "Average speed during Shift cannot exceed 75 MPH.")
    ];
  return null;
});
