/**
 * @generated SignedSource<<06445da85c925064f856463407fdb989>>
 * @relayHash f508e6642921681534bae64baea13296
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:_2DeuZXx0_OkfnV0mNp_4MwZc0rc7lmFj8WerauznNo

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ShiftTimesheetTabQuery$variables = {
  id: string;
};
export type ShiftTimesheetTabQuery$data = {
  readonly node: {
    readonly currentState?: {
      readonly name: EngagementStateName;
    };
    readonly gig?: {
      readonly estimatedMileage: number | null | undefined;
      readonly reconcilesAt: string | null | undefined;
    };
    readonly gigType?: GigType;
    readonly id?: string;
    readonly paymentInfo?: {
      readonly billableDuration: string;
      readonly timesheetPaymentStyle: EngagementTimesheetPaymentStyle;
    } | null | undefined;
    readonly systemTimesheet?: {
      readonly estimatedMileage: number | null | undefined;
      readonly startedCount: {
        readonly totalCount: number;
      } | null | undefined;
      readonly totalDurationWorked: string | null | undefined;
      readonly workerApprovals: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
    readonly workerTimesheet?: {
      readonly editable: boolean;
      readonly estimatedMileage: number | null | undefined;
      readonly isOverridden: boolean;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"TimesheetTabs_engagement" | "VerifyMileage_engagement" | "WithEngagementCapability_engagement" | "WorkerTimesheetInfoCard_engagement" | "WorkerUpdatedTimesheetReminder_engagement">;
  } | null | undefined;
};
export type ShiftTimesheetTabQuery = {
  response: ShiftTimesheetTabQuery$data;
  variables: ShiftTimesheetTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v8 = {
  "alias": "startedCount",
  "args": [
    (v6/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v9 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timesheetPaymentStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reconcilesAt",
  "storageKey": null
},
v20 = {
  "alias": "workerApprovals",
  "args": [
    (v6/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE approvedByType = WORKER"
    }
  ],
  "concreteType": "EngagementTimesheetApprovalsConnection",
  "kind": "LinkedField",
  "name": "approvals",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v27 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v28 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    {
      "transitionedAt": {
        "direction": "ASC"
      }
    }
  ]
},
v29 = [
  (v22/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v16/*: any*/)
],
v30 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v22/*: any*/),
          (v16/*: any*/),
          (v3/*: any*/),
          (v24/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transitionedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v29/*: any*/),
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v31 = {
  "alias": null,
  "args": [
    (v27/*: any*/),
    (v28/*: any*/),
    {
      "kind": "Literal",
      "name": "overridden",
      "value": true
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v30/*: any*/),
  "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShiftTimesheetTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "VerifyMileage_engagement",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": "systemTimesheet",
                    "args": (v5/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": "workerTimesheet",
                    "args": (v9/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"WORKER\")"
                  }
                ],
                "type": "Engagement",
                "abstractKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerTimesheetInfoCard_engagement"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerUpdatedTimesheetReminder_engagement"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TimesheetTabs_engagement"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "WithEngagementCapability_engagement",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCapability",
                    "kind": "LinkedField",
                    "name": "capabilities",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "restrictedBy",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Engagement",
                "abstractKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v4/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "systemTimesheet",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v9/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShiftTimesheetTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v22/*: any*/),
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/),
                  (v17/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v16/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "systemTimesheet",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v16/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v9/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v16/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/),
                  (v21/*: any*/),
                  (v23/*: any*/),
                  (v31/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "restrictedBy",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "finalTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "FINAL"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workerEta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": "inactiveCancelStates",
                "args": [
                  (v6/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE action = INACTIVE_CANCEL"
                  }
                ],
                "concreteType": "EngagementStateOrOverridesConnection",
                "kind": "LinkedField",
                "name": "states",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
              },
              {
                "alias": "original",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v2/*: any*/),
                  (v21/*: any*/),
                  (v8/*: any*/),
                  (v16/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v27/*: any*/),
                      (v28/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "overridden",
                        "value": false
                      }
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v30/*: any*/),
                    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "REQUESTER"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v25/*: any*/),
                  (v10/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/),
                  (v21/*: any*/),
                  (v16/*: any*/),
                  (v23/*: any*/),
                  (v31/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v18/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:_2DeuZXx0_OkfnV0mNp_4MwZc0rc7lmFj8WerauznNo",
    "metadata": {},
    "name": "ShiftTimesheetTabQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cc51e570392e80d286be71aac2d1dd7b";

export default node;
