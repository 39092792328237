import {
  ContentArea,
  Divider,
  ListRow,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import { TabLayout } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useState } from "react";
import TimesheetList from "../../engagement/TimesheetList";
import type { EngagementDisputeSummaryTabs_EngagementTimesheet$key } from "./__generated__/EngagementDisputeSummaryTabs_EngagementTimesheet.graphql";

interface Props {
  isRejected?: boolean;
  timesheetDisputed: boolean | undefined | null;
  additionalPayDisputed: boolean | undefined;
}

export function EngagementDisputeSummaryTabs({
  isRejected,
  paymentInfo,
  disputedFinalTimesheet,
  timesheetDisputed,
  additionalPayDisputed,
  result
}: FragmentContainerInnerComponentProps<
  EngagementDisputeSummaryTabs_EngagementTimesheet$key,
  Props
>) {
  const [activeTab, setActiveTab] = useState<"pay" | "timesheet">("pay");
  const billableDuration = paymentInfo?.billableDuration;
  const netPay = paymentInfo?.netPay;
  const approvedBillableDuration =
    disputedFinalTimesheet?.engagementTimesheet?.paymentInfo?.billableDuration;
  const diff = disputedFinalTimesheet?.diff;
  const finalTimesheet = disputedFinalTimesheet?.engagementTimesheet;
  const payRate = paymentInfo?.payRate;
  const basePaymentMinusFees = (
    Number(diff?.basePayment?.replace(" USD", "")) -
    Number(diff?.totalWorkerServiceFeeAmount?.replace(" USD", "") ?? 0)
  ).toFixed(2);
  return (
    <Stack>
      <Surface variant="outline">
        <TabLayout
          active={activeTab}
          onSelectTab={(tab) => setActiveTab(tab)}
          tabs={[
            {
              key: "pay",
              label: "DISPUTED PAY",
              renderContent: () => (
                <Stack variant="divider">
                  {timesheetDisputed && (
                    <ListRow
                      label="Additional Time Worked"
                      right={
                        <Text
                          color={isRejected ? "disabled" : "success"}
                          weight="semibold"
                          strikethrough={isRejected}
                        >
                          +{" "}
                          {duration.humanize(
                            diff?.billableDuration,
                            "semi-compact-no-spaces"
                          )}
                        </Text>
                      }
                    >
                      <Text variant="note" color="neutral">
                        added via Dispute Timesheet
                      </Text>
                    </ListRow>
                  )}
                  {timesheetDisputed && (
                    <ListRow
                      label="Additional Total Hourly Earnings"
                      right={
                        <Text
                          color={isRejected ? "disabled" : "success"}
                          weight="semibold"
                          strikethrough={isRejected}
                        >
                          + ${basePaymentMinusFees}
                        </Text>
                      }
                    >
                      <Text variant="note" color="neutral">
                        (
                        {duration.humanize(
                          diff?.billableDuration,
                          "hours-decimal"
                        )}
                        hr x {currency.humanizeRate(payRate)}) - $1.00/hr T&S
                        Fee
                      </Text>
                    </ListRow>
                  )}
                  {additionalPayDisputed && (
                    <ListRow
                      label="Additional Payment Request"
                      right={
                        <Text
                          color={isRejected ? "disabled" : "success"}
                          weight="semibold"
                          strikethrough={isRejected}
                        >
                          + {currency.humanize(diff?.additionalPayment)}
                        </Text>
                      }
                    />
                  )}
                  {timesheetDisputed && (
                    <ListRow
                      label="New Total Time Worked"
                      right={
                        <Text weight="semibold" strikethrough={isRejected}>
                          +{" "}
                          {duration.humanize(
                            billableDuration,
                            "semi-compact-no-spaces"
                          )}
                        </Text>
                      }
                    >
                      <Text variant="note" color="neutral">
                        {duration.humanize(
                          finalTimesheet?.paymentInfo?.billableDuration,
                          "semi-compact-no-spaces"
                        )}{" "}
                        (Approved){" "}
                        <Text
                          color={isRejected ? "disabled" : "success"}
                          strikethrough={isRejected}
                        >
                          +{" "}
                          {duration.humanize(
                            diff?.billableDuration,
                            "semi-compact-no-spaces"
                          )}
                        </Text>
                      </Text>
                    </ListRow>
                  )}
                  <ListRow
                    label="New Total Earnings"
                    right={
                      <Text weight="semibold" strikethrough={isRejected}>
                        + {currency.humanize(netPay)}
                      </Text>
                    }
                  >
                    <Text variant="note" color="neutral">
                      {currency.humanize(finalTimesheet?.paymentInfo?.netPay)}{" "}
                      (Approved){" "}
                      {timesheetDisputed && (
                        <>
                          <Text
                            color={isRejected ? "disabled" : "success"}
                            strikethrough={isRejected}
                          >
                            + ${basePaymentMinusFees}
                          </Text>{" "}
                        </>
                      )}
                      {diff?.additionalPayment !== "0 USD" && (
                        <Text
                          color={isRejected ? "disabled" : "success"}
                          strikethrough={isRejected}
                        >
                          + {currency.humanize(diff?.additionalPayment)}
                        </Text>
                      )}
                    </Text>
                  </ListRow>
                </Stack>
              )
            },
            {
              key: "timesheet",
              label: "DISPUTED TIMESHEET",
              renderContent: () =>
                timesheetDisputed ? (
                  <ContentArea>
                    {diff?.basePayment === "0 USD" ? (
                      <Text>
                        You did not update a Timesheet for this dispute.
                      </Text>
                    ) : (
                      <>
                        <TimesheetList
                          fragmentRef={result}
                          variant="worker"
                          workerEta={null}
                          endsAt={null}
                        />
                        <Spacer />
                        <Divider />
                        <Stack variant="divider">
                          <ListRow
                            label="Time Worked"
                            right={duration.humanize(
                              approvedBillableDuration,
                              "semi-compact-no-spaces"
                            )}
                          >
                            <Text variant="note">Approved by Requester</Text>
                          </ListRow>
                          <ListRow
                            label="Time Added"
                            right={
                              <Text
                                color={isRejected ? "disabled" : "success"}
                                weight="semibold"
                                strikethrough={isRejected}
                              >
                                +{" "}
                                {duration.humanize(
                                  diff?.billableDuration,
                                  "semi-compact-no-spaces"
                                )}
                              </Text>
                            }
                          >
                            <Text variant="note" color="neutral">
                              via Dispute Timesheet
                            </Text>
                          </ListRow>
                          <ListRow
                            label="Additional Earnings"
                            right={
                              <Text
                                color={isRejected ? "disabled" : "success"}
                                weight="semibold"
                                strikethrough={isRejected}
                              >
                                + ${basePaymentMinusFees}
                              </Text>
                            }
                          >
                            <Text variant="note" color="neutral">
                              (
                              {duration.humanize(
                                diff?.billableDuration,
                                "hours-decimal"
                              )}
                              hr x {currency.humanizeRate(payRate)}) - $1.00/hr
                              T&S Fee
                            </Text>
                          </ListRow>
                        </Stack>
                        <Divider />
                      </>
                    )}
                  </ContentArea>
                ) : (
                  <ContentArea>
                    <Text>
                      You did not update a Timesheet for this dispute.
                    </Text>
                  </ContentArea>
                )
            }
          ]}
        />
      </Surface>
    </Stack>
  );
}

export default createRelayFragmentContainer<
  EngagementDisputeSummaryTabs_EngagementTimesheet$key,
  Props
>(
  graphql`
    fragment EngagementDisputeSummaryTabs_EngagementTimesheet on EngagementTimesheet {
      ...TimesheetList_timesheet @arguments(overridden: false)
      paymentInfo {
        billableDuration
        netPay
        payRate
      }
      timezone
      disputedFinalTimesheet {
        diff {
          billableDuration
          basePayment
          totalPayment
          totalWorkerServiceFeeAmount
          additionalPayment
        }
        engagementTimesheet {
          paymentInfo {
            billableDuration
            netPay
          }
        }
      }
    }
  `,
  EngagementDisputeSummaryTabs
);
